<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">Confirma datos para consultar historial crediticio.</h3>
                <p>Favor verifica tus datos.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row mb-base">
                        <div :class="colClass">
                            <p class="bold">Nombre</p>
                            <p>{{ getApplicantName }}</p>
                        </div>
                        <div :class="colClass">
                            <p class="bold">Lugar y fecha de nacimiento</p>
                            <p>{{ getBirthDate }}</p>
                        </div>
                        <div :class="colClass">
                            <p class="bold">Género</p>
                            <p>{{ getGender.toUpperCase() }}</p>
                        </div>
                        <div class="w-full"></div>
                        <div :class="colClass">
                            <p class="bold">RFC</p>
                            <p>{{ getRFC }}</p>
                        </div>
                        <div :class="colClass">
                            <p class="bold">CURP</p>
                            <p>{{ getCURP }}</p>
                        </div>
                        <div :class="colClass">
                            <p class="bold">Teléfono</p>
                            <p>{{ `${getDialCode} ${phoneFormat(getPhone)}` }}</p>
                        </div>
                        <div class="w-full"></div>
                        <div v-if="!editAddress" class="vx-col sm:w-full md:w-7/12 mb-5">
                            <p class="bold">Dirección</p>
                            <div class="flex">
                                <p class="mb-2">
                                    {{ getAddress }}
                                </p>
                                <vs-button @click.stop="editAddress = true" type="line" size="small" color="primary" class="ml-2">Editar</vs-button>
                            </div>
                        </div>
                        <div v-else class="vx-col sm:w-full md:w-10/12 mb-5">
                            <vx-card>
                                <ApplicantAddress 
                                    :project="base" 
                                    :isEdit="true" 
                                    @updated="getProjectData"/>
                            </vx-card>
                        </div>
                    </div>

                    <div class="vx-row">
                        <div class="vx-col w-10/12">
                            <vx-card>
                                <CreditScoreRequestComponent
                                v-if="isMounted"
                                :isMoral="isMoral"
                                :currentUser="base.client.user"
                                :isBusiness="isMoral"
                                :isApplicant="true"
                                :projectId="projectId"
                                @updated="getProjectData"></CreditScoreRequestComponent>
                            </vx-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CreditScoreRequestComponent from '../../../../components/profile_shared/CreditScoreRequest.vue';
import ApplicantAddress from './ApplicantAddress.vue';
import formatHelper from "@mixins/formatHelper.js"

export default {
    name: "CreditScoreRequest",
    props: {
        project: Object
    },
    mixins: [formatHelper],
    components: { ApplicantAddress, CreditScoreRequestComponent },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col sm:w-full md:w-3/12 mb-5",
        requiredRules: "required",
        errorMssg: null,
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        editAddress: false,
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        this.isMounted = true;
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        personal() {
            return this.base.client.user.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        getApplicantName() {
            if (this.isMoral) {
                return this.business.name;
            }
            else {
                return `${this.personal.first_name} ${this.personal.second_name || ""} ${this.personal.last_name_1} ${this.personal.last_name_2 || ""}`;
            }
        },
        getBirthDate() {
            if (this.isMoral) {
                return this.business.name;
            }
            else {
                return this.personal.birth_state_named + " | " + this.personal.birth_date;
            }
        },
        getGender() {
            if (this.isMoral) {
                return this.business.name;
            }
            else {
                let gender = "";
                if (this.personal.gender == 1) {
                    gender = "Hombre";
                }
                else if (this.personal.gender == 2) {
                    gender = "Mujer";
                }
                return gender;
            }
        },
        getRFC() {
            if (this.isMoral) {
                return this.business.rfc;
            }
            else {
                return this.personal.rfc;
            }
        },
        getCURP() {
            if (this.isMoral) {
                return this.business.curp;
            }
            else {
                return this.personal.curp;
            }
        },
        getAddress() {
            if (this.isMoral) {
                return this.business.address;
            }
            else {
                return this.personal.address.full_address;
            }
        },
        getPhone() {
            if (this.isMoral) {
                return this.business.phone.phone;
            }
            else {
                return this.personal.phone.phone;
            }
        },
        getDialCode() {
            if (this.isMoral) {
                return this.business.phone.country_calling_code;
            }
            else {
                return this.personal.phone.country_calling_code;
            }
        },
    },
    methods: {
        async getProjectData() {
            this.editAddress = false;
            await this.$emit("updated", 1);
        }
    },
}
</script>