<template>
    <div id="applicant-personal-profile" class="mt-base">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h2>{{ currentTitle.title }}</h2>
                <p>{{ currentTitle.subtitle }}</p>
                <vs-divider></vs-divider>
            </div>
            <!-- INFORMARCIÓN SOBRE LA DIRECCIÓN -->
            <!-- <div v-if="current_step == 'pm_2_business_address_confirmation'" class="ml-4">
                <div class="vx-col w-full">
                    <p class="bold black">¿Tu dirección personal es la misma dirección de operación de tu negocio?</p>
                    <div class="vx-row">
                        <div class="vx-col w-2/6 mb-8 mt-2"
                            v-for="(item, index) in addressOptions" :key="index">
                            <rg-radio 
                                class="w-full" groupName="addressOptions" :label="item.label"
                                :value="item.value" :desc="item.desc" v-model="sameAddress" />
                        </div>
                    </div>
                </div>
    
                <div v-if="sameAddress == '1'" class="vx-col sm:w-full md:w-2/3 lg:w-2/3">
                    <div class="address-card">
                        <div class="flex">
                            <feather-icon 
                                icon="MapPinIcon"
                                svgClasses="w-5 h-5 m-1 mr-2 mt-0"
                                class="cursor-pointer text-accent" />
                            <p class="bold mb-1">Dirección de operación</p>
                        </div>
                        <div class="flex">
                            <p class="ml-8">{{ business_address.full_address  }}</p>
                        </div>
                    </div>
                </div>
                <div v-else class="vx-col w-full">
                    <div v-if="!isEdit" class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                        <div class="address-card">
                            <div class="flex">
                                <feather-icon 
                                    icon="MapPinIcon"
                                    svgClasses="w-5 h-5 m-1 mr-2 mt-0"
                                    class="cursor-pointer text-accent" />
                                <p class="bold mb-1">Dirección personal</p>
                            </div>
                            <div class="flex">
                                <p class="ml-8">{{ business_personal_address.full_address  }}</p>
                                <vs-button size="small" type="line" @click.stop="isEdit = true">Editar</vs-button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <ApplicantOnboardingAddress  @updated="getProjectData" :onboardingStepData="onboardingStepData.client" :isMoral="false" :isFromLoan="true"/>
                    </div>
                </div>
            </div>  -->

            <!-- INFORMACIÓN SOBRE EL RFC Y CURP -->
            <!-- <div v-else-if="current_step == 'pm_3_personal_profile_address'">
                <div class="vx-row ml-2">
                    <div class="vx-col sm:w-full md:w-8/12 lg:w-8/12">
                        <div class="vx-row">
                            <div class="vx-col w-full mb-base mt-2">
                                <vs-input
                                    @input="(val) => (rfc = rfc.toUpperCase())"
                                    class="w-full"
                                    label="RFC *"
                                    name="rfc"
                                    v-validate="requiredRules"
                                    v-model.lazy="rfc"
                                    :danger="hasError('rfc')"
                                    :danger-text="errorText('rfc')"
                                    :success="isSuccess('rfc')"
                                    icon-pack="feather"
                                    :placeholder="requiredPlaceholder"
                                />
                                <a
                                    class="inline-link-primary"
                                    target="_blank"
                                    rel="noopener"
                                    :href="$sanitizeUrl(rfcUrl)"
                                    >Consultar mi RFC</a
                                > 
                            </div>
                            <div class="vx-col w-full">
                                <vs-input
                                    @input="(val) => (curp = curp.toUpperCase())"
                                    class="w-full"
                                    label="CURP *"
                                    name="curp"
                                    v-validate="requiredRules"
                                    v-model.lazy="curp"
                                    :danger="hasError('curp')"
                                    :danger-text="errorText('curp')"
                                    :success="isSuccess('curp')"
                                    icon-pack="feather"
                                    :placeholder="requiredPlaceholder"
                                />
                                <a
                                    class="inline-link-primary"
                                    target="_blank"
                                    rel="noopener"
                                    :href="$sanitizeUrl(curpUrl)"
                                    >Consultar mi CURP</a
                                >
                            </div>
                        </div>
                        <vs-alert
                            v-if="errorMssg"
                            icon-pack="feather"
                            icon="icon-alert-triangle"
                            class="mb-5 mt-5"
                            color="danger">
                            <span class="font-regular">{{ errorMssg }}</span>
                        </vs-alert>
                    </div>
                </div>
            </div> -->

            <!-- revisión de historial crediticio -->
            <div>
                <div class="vx-row m-2 p-2">
                    <div class="vx-col sm:w-full md:w-2/3 address-card p-6">
                        <CreditScoreRequest
                            v-if="isMounted"
                            :isMoral="isMoral"
                            :currentUser="base.client.user"
                            :isBusiness="isMoral"
                            :isApplicant="true"
                            :projectId="base.id"
                            @updated="getProjectData"/>
                    </div>
                </div>
            </div>
            <!-- revisión de historial crediticio -->
        </div>
    </div>
</template>

<script>
import ApplicantOnboardingAddress from '../../../onboarding/components/business/ApplicantOnboardingAddress.vue';
import CreditScoreRequest from '../../../../../components/profile_shared/CreditScoreRequest.vue';

import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";

const LOAN_TYPE_ECOTECNOLOGIAS = 1;
const LOAN_TYPE_PERSONAL = 5;
const LOAN_TYPE_PYME = 4;   

const addressOptions = [
    { label: "Sí, es la misma dirección", value: "1", desc: null },
    { label: "No, son diferentes", value: "2", desc: null },
];

export default {
    name: "ApplicantPersonalProfile",
    props: ["onboardingStepData", "isMoral"],
    mixins: [inputHelper, formatHelper],
    components: {
        ApplicantOnboardingAddress,
        CreditScoreRequest
    },
    data: () => ({
        isEdit: false,
        addressOptions: addressOptions,
        errorMssg: null,
        sameAddress: "1",
        requiredRules: "required",
        requiredPlaceholder: "(Requerido)",
        rfcUrl: "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
        curpUrl: "https://www.gob.mx/curp/",
        rfc: null,
        curp: null,
        isMounted: false,

    }),
    computed: {
        base() {
            return this.onboardingStepData;
        },
        finance() {
            return this.base.finance;
        },
        hasOffer() {
            return this.base.loan_proposal_timestamp != null && this.base.finance.credit_acceptance_date == null;
        },
        current_step() {
            return this.onboardingStepData.loan_request_step;
        },
        business_personal() {
            return this.onboardingStepData.client.user.business.personal;
        },
        business_address() {
            return this.onboardingStepData.client.user.business.address;
        },
        business_personal_address() {
            return this.onboardingStepData.client.user.business.personal.address;
        },
        currentTitle() {
            let title = {
                title : "",
                subtitle : ""
            };

            switch(this.current_step) {
                case "pm_2_business_address_confirmation":
                    title.title = "Información personal";
                    title.subtitle = "Confirma la información de tu domicilio";
                    break;
                case "pm_3_personal_profile_address":
                    title.title = "Valida tu RFC y CURP";
                    title.subtitle = "Valida tus datos";
                    break;
                case "pm_4_personal_profile_curp":
                    title.title = "Autorización de consulta de Buró de Crédito";
                    title.subtitle = `Revisaremos la el historial crediticio del representante legal.`;
                    break;
                case "pm_5_personal_profile_sic_check":
                    title.title = "Estamos preparando tu oferta";
                    title.subtitle = `En unos minutos te compartiremos el resultado.`;
                    break;

                    default: 
                        title.title = "Información personal";
                        title.subtitle = "Ya nos haz proporcionado la información necesaria para tu solicitud de crédito.";
                    break;
            }

            return title;
        },
        loanType() {
            const loanType = this.base.finance.loan_type;
            let loanTypeText = "";
            if(loanType === LOAN_TYPE_ECOTECNOLOGIAS) {
                loanTypeText = "Ecotecnologías";
            } else if(loanType === LOAN_TYPE_PERSONAL) {
                loanTypeText = "Personal";
            } else if(loanType === LOAN_TYPE_PYME) {
                loanTypeText = "PYME";
            }

            return loanTypeText;
        },
    },
    beforeMount() {
        if (this.business_address.id != this.business_personal_address.id && this.business_personal_address.neighborhood_id != null) {
            this.sameAddress = "2";
        } else {
            this.isEdit = true;
        }

        this.rfc = this.business_personal.rfc;
        this.curp = this.business_personal.curp;

        this.isMounted = true;
    },
    mounted() {
        if (this.current_step == "pm_5_personal_profile_sic_check" && !this.hasOffer) {
            // this.offerIsNotReadyNotif();
        }
    },
    methods: {
        async getProjectData() {

            if(this.current_step == "pm_4_personal_profile_curp") {
                await axios.put(
                    `/api/loan-onboarding/put/${this.base.id}/update-sic-check`, {
                        project_id: this.base.id,
                    }
                );
                // this.offerIsNotReadyNotif();
            }
            this.isEdit = false;
            this.$emit('updated', 1);
        },
        async savePersonalIds() {
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return false;
            }
            let validRfc = this.isValidRfcFormat(this.rfc);
            if(!validRfc){
                this.errorMssg = "La estructura de la clave RFC es incorrecta."
                return false;
            }
            let validCurp = this.isValidCurpFormat(this.curp);
            if(!validCurp){
                this.errorMssg = "La estructura de la clave CURP es incorrecta."
                return false;
            }
            this.showLoading(true);
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    project_id: this.base.id,
                    rfc: this.rfc,
                    curp: this.curp,
                };
                const res = await axios.put(
                    `/api/loan-onboarding/put/${this.ApplicantId}/update-bp-ids`,
                    payload
                );

                if(res.data == "success") {
                    this.$emit("updated", 1);
                    this.showLoading(false);
                    return true;
                } else {
                    this.showLoading(false);
                    this.errorMssg = res.data;
                    return false;
                }
            } catch (e) {
                this.showLoading(false);
                console.log(e);
                this.errorMssg = "Ocurrió un error al guardar los datos.";
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        offerIsNotReadyNotif() {
            this.$vs.dialog({
                color: 'black',
                title: 'Estamos preparando tu oferta',
                text: `En unos minutos te compartiremos el resultado, mientras tanto puedes continuar llenando tu solicitud sí así lo deseas.`,
                acceptText: "Continuar",
                cancelText: 'Notificarme cuando esté lista',
                accept: "",
                cancel: this.goToHomePage
            });
        },
        async goToHomePage(){
            await this.$router.push({name: 'inicioSolicitante'});
        },
    }
}
</script>

<style>

.address-card {
    border-radius: 8px;
    border: 1px solid #28DE18;
    padding: 1rem;
    width: fit-content;
}

.vs-button.small:not(.includeIconOnly) {
    padding: 1px
}
</style>