<template>
    <div id="project_fg_location_address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">Sobre la instalación.</h3>
                <p>Domicilio donde se realizará la instalación de la tecnología.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row">
                        <div v-for="section in allSections" :key="section.id" :class="colClass">
                            <div class="vx-row">
                                <template v-for="f in section.fields">
                                    <!-- Campos automaticos -->
                                    <form-field 
                                        :ref="`section_id_${section.id}`" 
                                        v-if="isAutoFormField(f) && showFields(f.db_table, f.db_field)" 
                                        :key="f.id"
                                        :f="f" 
                                        :dataContainer="payload"
                                        :collectionsRoot="collections" 
                                        :onChangeReceptor="onFieldChange"
                                        :hasBackofficeAccess="false" 
                                        :blockedByDocument="f.blockedByDocument"
                                        :evaluator="evaluateCondition" 
                                        :inputClassLarge="true"
                                        :countriesList="collections.countries" 
                                        :editableByBackoffice="true" />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSections(`section_id_${current_section}`)">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const sectionsToContainers = [
    {
        id: 37, containers: [
            { db: 'location', path: 'location' },
            { db: 'addresses', path: 'location.address' }
        ]
    },
];
export default {
    name: "ProjectFGLocationAddress",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: {
        project: Object
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col w-full mb-5",
        pmSections: [37],
        pfSections: [37],
        allSections: [],
        formSections: [],

        payload: {},

        allSections: [],
        formSections: [],

        documentsFields: [],
        documentsStates: [],

        validatedDocuments: [],
        allDocumentFields: [],
        mexicoCountryId: 700,
        collections: {
            existingLocations: [],
            countries: [],
            cities: [],
            family_groups: [],
            relationTypes: [],

            states_supplier_workplan_address: [],
            cities_supplier_workplan_address: [],
            neighborhoods_supplier_workplan_address: []
        },

        allAddresses: [],
        clientAddress: null,
        location_id: null,

        validatedFields: [],
        validatedDocuments: [],
        allDocumentFields: [],
        addressEditFlag: false,
        addressPayload: {},
        fgLocation: {},
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        await this.getCollections();
        await this.getSections();
        this.isMounted = true;
        this.setPayloadInformation(this.location_address);
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        current_section() {
            return this.isMoral ? this.pmSections[0] : this.pfSections[0];
        },
        personal() {
            return this.base.client.user.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        is_applicant_platform() {
            return true;
        },
        kycProfile() {
            return this.base.client.kyc_profile;
        },
        location() {
            if (this.isMounted) {
                return this.base.fg_locations[0].location;
            } else {
                return {};
            }
        },
        location_address() {
            if (this.isMounted) {
                return this.location.address;
            } else {
                return {};
            }
        },
        should_fill_new_location() {
            return true;
        },
        location_is_defined() {
            return this.location != null;
        },
    },
    methods: {
        async getSections() {
            this.showLoading(true);
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this, true);
                });
            });
            this.showLoading(false);
        },
        getContainersForSection(section_id) {
            let c = sectionsToContainers.find(f => f.id == section_id);
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.countries = colls.countriesList;
                this.collections.foreign_countries = colls.foreignCountriesList;
                this.collections.states = colls.statesList;

            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        setPayloadInformation(fgLocation){
            this.payload.address$city_id = fgLocation.city_id;
            this.payload.address$country_id = fgLocation.country_id;
            this.payload.address$ext_number = fgLocation.ext_number;
            this.payload.address$int_number = fgLocation.int_number;
            this.payload.address$municipality = fgLocation.municipality;
            this.payload.address$neighborhood = fgLocation.neighborhood;
            this.payload.address$other_city = fgLocation.other_city;
            this.payload.address$other_state = fgLocation.other_state;
            this.payload.address$state_id = fgLocation.state_id;
            this.payload.address$street = fgLocation.street;
            this.payload.address$zipcode = fgLocation.zipcode;
            this.payload.address$id = fgLocation.id

            this.reload_cities();
        },
        // GUARDAR
        async saveSections(gslug) {
            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.missingFieldsNotif();
                }
            } else {
                this.showLoading(true, "Guardando información...");
                //guardar solo la informacion que se ha modificado
                let payload = this.packNested(this.payload);
                if (this.objectIsEmpty(payload)) {
                    this.saveSuccessNotif();
                    await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                    await this.$emit("updated", 1);
                    this.showLoading(false);
                    return;
                }
                if(this.location) {
                    payload.location_id = this.location.id;
                    payload.address.id = this.location.address.id;
                }

                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(payload);
                    await axios.put(`/api/loan-onboarding/put/${this.base.id}/update-location-address`, payload);
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    await this.$emit("updated", 1);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.showLoading(false);
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }
            }
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;

                    obj = { id: cc.container.id };
                    fields.forEach(f => {
                        obj[f.fname] = cc.container[f.fname];
                    });

                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            f.setError(sp.error);
                            this.errorNotif(n, sp.error, 10000);
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        verify_zip_code_supplier_workplan_address() {
            this.verifyPayloadZipCode(this.payload, {
                addressName: 'address',
                statesArray: 'states_supplier_workplan_address', 
                citiesArray: 'cities_supplier_workplan_address', 
                neighborhoodsArray: 'neighborhoods_supplier_workplan_address'
            });
        },
        set_zip_code_values_supplier_workplan_address() {
            this.setPayloadZipCodeAddressValues(this.payload.address$id, this.payload, {
                addressName: 'address',
                statesArray: 'states_supplier_workplan_address', 
                citiesArray: 'cities_supplier_workplan_address', 
                neighborhoodsArray: 'neighborhoods_supplier_workplan_address'
            });
        },
        getTitle() {
            return this.isEdit ? "Edita tu domicilio." : "Información de tu domicilio."
        },
        set_residence_ownership() {
            if (this.kycProfile !== null) {
                const residenceOwnership = {
                    "own": "Propio",
                    "rented": "Rentada",
                    "family": "De un familiar",
                    "shared": "Compartida",
                    "irregular": "Otro",
                };
                this.kycProfile.residence_ownership = (residenceOwnership[this.kycProfile.residence_ownership] || null);
            }

            return null;
        },
        async reload_cities(){
            try {
                let res = await axios.get('/api/register/cities/' + this.payload.address$state_id);
                this.collections.cities = res.data;
                // if(this.isMounted && !this.isRefreshing) this.payload.address$city_id = null;
            } catch (e) {
                this.warn(e);
            }
        },
        translateLocationType(value) {
            if (value) {
                if (value == "home") {
                return 1;
                } else if (value == "business") {
                return 2;
                } else {
                return 3;
                }
            }
            return null;
        },
        showFields(dbTable, dbField) {
            if(dbTable == 'addresses') {
                return true;
            } else if(
                dbField == 'renting_time'
                || dbField == 'type'
            ) {
                return true;
            }

            return false;
        },
    }
}
</script>