<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">{{ title }}</h3>
                <p>Favor completa la siguiente información.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row">
                        <div :class="colClass">
                            <vs-input
                                @input="(val) => (payload.companyName = payload.companyName.toUpperCase())"
                                class="w-full"
                                label="Nombre de la empresa o negocio donde laboras"
                                name="companyName"
                                v-validate="requiredRules"
                                v-model.trim="payload.companyName"
                                :danger="hasError('companyName')"
                                :danger-text="errorText('companyName')"
                                :success="isSuccess('companyName')"
                                icon-pack="feather"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <!-- <div :class="colClass">
                            <vs-select label="¿Cuál es tu puesto? *" name="job" v-validate="requiredRules"
                                :danger="hasError('job')"
                                :danger-text="errorText('job')"
                                :success="isSuccess('job')"
                                placeholder="Selecciona una opción."
                                v-model.lazy="payload.job"
                                class="mr-2 w-full">
                                <vs-select-item v-for="(item, index) in jobOptions"
                                    :text="item.label" :key="index"
                                    :value="item.value"></vs-select-item>
                            </vs-select>
                        </div> -->
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Antigüedad (años)"
                                name="senority"
                                v-validate="'required|numeric|min:0|max:100'"
                                v-model.number="payload.senority"
                                :danger="hasError('senority')"
                                :danger-text="errorText('senority')"
                                :success="isSuccess('senority')"
                                icon-pack="feather"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <div v-if="!isRetired" :class="colClass">
                            <label class="vs-input--label">Teléfono del empleo</label>
                            <div class="flex">
                                <vue-country-code
                                    name="dialCode"
                                    class="vs-custom"
                                    data-vv-name="dialCode"
                                    :enableSearchField="true"
                                    searchPlaceholderText="Buscar código de país"
                                    :enabledCountryCode="true"
                                    @onSelect="onSelect"
                                    :preferredCountries="['MX']"
                                    :danger="hasError('dialCode')"
                                    :danger-text="errorText('dialCode')"
                                    :success="isSuccess('dialCode')">
                                </vue-country-code>
                                <vs-input
                                    class="w-full"
                                    label=""
                                    name="phone"
                                    type="tel"
                                    v-validate.persist="'required|numeric|min:10|max:10'"
                                    v-model.lazy="payload.phoneReq.phone"
                                    :danger="hasError('phone')"
                                    :danger-text="errorText('phone')"
                                    :success="isSuccess('phone')"
                                    icon-pack="feather"
                                    :placeholder="requiredPlaceholder"
                                />
                            </div>
                        </div>
                        <div :class="colClass">
                            <vs-select label="¿Estás afiliado al IMSS?" name="job" v-validate="requiredRules"
                                :danger="hasError('job')"
                                :danger-text="errorText('job')"
                                :success="isSuccess('job')"
                                placeholder="Selecciona una opción."
                                v-model.lazy="payload.imss"
                                class="mr-2 w-full">
                                <vs-select-item v-for="(item, index) in boolOptions"
                                    :text="item.label" :key="index"
                                    :value="item.value"></vs-select-item>
                            </vs-select>
                        </div>
                        <!-- <div :class="colClass">
                            <vs-select label="¿Tienes seguro de gastos médicos? *" name="job" v-validate="requiredRules"
                                :danger="hasError('job')"
                                :danger-text="errorText('job')"
                                :success="isSuccess('job')"
                                placeholder="Selecciona una opción."
                                v-model.lazy="payload.assurance"
                                class="mr-2 w-full">
                                <vs-select-item v-for="(item, index) in boolOptions"
                                    :text="item.label" :key="index"
                                    :value="item.value"></vs-select-item>
                            </vs-select>
                        </div> -->
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSection()">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const jobOptions = [
    { label: "Puestos directivos", value: 2 },
    { label: "Puestos de mando medio", value: 1 },
    { label: "Puestos de supervisión, profesionales y técnicos", value: 3 },
    { label: "Puestos operativos y de apoyo", value: 4 },
]
const boolOptions = [
    { label: "Si", value: 1 },
    { label: "No", value: 0 }
]

export default {
    name: "PersonalEmploymentInformation",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: {
        project: Object
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col sm:w-full md:w-5/12 mb-5",
        requiredRules: "required",
        errorMssg: null,
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        payload: {
            companyName: null,
            job: null,
            senority: null,
            phoneReq: {
                phone: null,
                country_calling_code: null,
                phone_id: null
            },
            imss: null,
            assurance: null,
            personalProfileId: null,
            ppIncomeSourceId: null
        },
        jobOptions: jobOptions,
        boolOptions: boolOptions
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        this.isMounted = true;
        this.setData();
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        personal() {
            return this.base.client.user.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        incomeSources() {
            return this.personal.p_p_income_sources[0];
        },
        personalCreditProfile() {
            return this.personal.credit_profile;
        },
        isRetired() {
            if(this.isMounted) {
                return this.personal.personal_occupation_id == 5;
            } else {
                return false;
            }
        },
        isMerchant() {
            if(this.isMounted) {
                return this.personal.personal_occupation_id == 2 || this.personal.personal_occupation_id == 3;
            } else {
                return false;
            }
        },
        isEmployee() {
            if(this.isMounted) {
                return this.personal.personal_occupation_id == 1;
            } else {
                return false;
            }
        },
        title() {
            if(this.isRetired) {
                return "Cuéntanos sobre tu último trabajo.";
            } else if(this.isMerchant) {
                return "Cuéntanos sobre tu ocupación.";
            } else if(this.isEmployee) {
                return "Cuéntanos sobre la empresa en la que trabajas.";
            } else {
                return "Detalles de empleo.";
            }
        }
    },
    methods: {
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        onSelect({ dialCode }) {
            this.payload.phoneReq.country_calling_code = `+${dialCode}`;
        },
        setData() {
            this.payload.personalProfileId = this.personal.id;
            if(this.incomeSources != null && this.incomeSources != undefined) {
                this.payload.senority = this.incomeSources.senority;
                this.payload.companyName = this.incomeSources.employer;
                this.payload.phoneReq.phone_id = this.incomeSources.employer_phone_id;
                if(!this.isRetired) {
                    this.payload.phoneReq.phone = this.incomeSources.phone.phone;
                }
                this.payload.ppIncomeSourceId = this.incomeSources.id;
            }
            this.payload.imss = this.personalCreditProfile.social_security_affiliated;
            this.payload.assurance = this.personalCreditProfile.major_medical_health_insurance;
        },
        async saveSection() {
            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                this.missingFieldsNotif();
                return false;
            }

            try {
                this.showLoading(true);
                const res = await axios.post(`/api/loan-onboarding/post/${this.ApplicantId}/update-job`, this.payload);
                this.showLoading(false);
                this.saveSuccessNotif();
                await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                await this.$emit("updated", 1);
            } catch (error) {
                console.log(error);
                this.showLoading(false);
            }
        }
    }
}
</script>