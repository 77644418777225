<template>
    <div id="applicant-documents-control">
        <div v-if="hasDocumentationCompletedDate" class="vx-row mb-4">
            <div class="vx-col sm:w-full md:w-5/12 mb-4">
                <h3 class="mb-base">Tu solicitud está en revisión.</h3>
                <p class="mb-2 black">
                    <span class="bold text-primary">¡Listo! Ya tenemos todo.</span> <br>
                    Estamos analizando tu información para aprobar el crédito, <br> esto puede tomar algunas horas, te notificaremos por correo.
                </p>
            </div>
            <div class="vx-col sm:w-full md:w-1/2 mb-4">
                <img class="sm:w-1/5 md:w-1/5":src="getIcon('04')" alt="Completando solicitud">
            </div>
        </div>
        <div v-if="menuOption == 0">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h3 class="mb-1">Documentos requeridos.</h3>
                    <p>Podrás firmar el contrato una vez que validemos la información.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <!-- INFORMACIÓN DE SOLICITANTE -->
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 2">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información del solicitante</h5>
                            <p class="mb-2">Verificación de identidad, comprobante de ingreso, comprobante de domicilio y cuenta CLABE.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getApplicantData().color">{{ getApplicantData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INFORMACIÓN DEL OS -->
            <div v-if="needGuarantee" class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 3">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información del obligado solidario</h5>
                            <p class="mb-2">Comprobante de domicilio y verificación de identidad.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getGuaranteeData().color">{{ getGuaranteeData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INFORMACIÓN DE LA UBICACIÓN -->
            <div v-if="isEcotechnologiesLoan" class="vx-row mb-base m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 1">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información de la ubicación de instalación</h5>
                            <p class="mb-2">Documento de propiedad</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getLocationData().color">{{ getLocationData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <vs-button v-if="isEcotechnologiesLoan" :disabled="!canContinueSFV" class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button>
                    <vs-button v-else :disabled="!canContinue" class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button>
                </div>
            </div>
        </div>
        <!-- INFORMACIÓN DE LA UBICACIÓN -->
        <div v-if="menuOption == 1">
            <LocationDocument :fgLocation="fgLocation" :projectId="project.id" @on-back="goBack" :document="projectDocument"/>
        </div>
        <!-- INFORMACIÓN DEL SOLICITANTE -->
        <div v-if="menuOption == 2">
            <ApplicantPersonalDocuments :onboardingStepData="project"  @on-back="goBack" @updated="getProjectData"/>
        </div>
        <!-- INFORMACIÓN DEL OS -->
        <div v-if="menuOption == 3">
            <GuaranteeDocumentsControl :onboardingStepData="project" @on-back="goBack" @updated="getProjectData"/>
        </div>
    </div>
</template>

<script>
import ApplicantPersonalDocuments from './ApplicantPersonalDocuments.vue';
import LocationDocument from './shared_components/LocationDocument.vue';
import GuaranteeDocumentsControl from './guarantee/GuaranteeDocumentsControl.vue';

const DOCUMENT_REQUIRED_ID = 32;
const DOCUMENT_PROJECT_REQUIRED_ID = 48;
const PF_GUARANTEE_ADDRESS_PROOF_ID = 44;

export default {
    name: "ApplicantDocumentsControl",
    props: {
        project: Object
    },
    components: {
        ApplicantPersonalDocuments,
        LocationDocument,
        GuaranteeDocumentsControl
    },
    data: () => ({
        menuOption: 0,
        document: null,
        guaranteeDocument: null,
        projectDocument: null,
        isMounted: false
    }),
    async created() {
        this.isMounted = false;
        await this.getDocument();
        this.isMounted = true;
        if(this.isEcotechnologiesLoan) {
            await this.getProjectDocument();
        }
    },
    computed: {
        canContinueSFV() {
            return (this.getApplicantData().status == 'Completado' || this.getApplicantData().status == 'Cargado') 
                    && (this.getGuaranteeData().status == 'Cargado' || this.getGuaranteeData().status == 'Completado')
                    && (this.getLocationData().status == 'Cargado' || this.getLocationData().status == 'Completado')
        },
        canContinue() {
            return (this.getApplicantData().status == 'Completado' || this.getApplicantData().status == 'Cargado') && (this.getGuaranteeData().status == 'Cargado' || this.getGuaranteeData().status == 'Completado')
        },
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        // hasAlreadyDocument() {
        //     return this.document && this.document.id;
        // },
        isEcotechnologiesLoan() {
            if(this.isMounted) {
                return this.project.finance?.loan_type == 1 && this.technologySelected?.family_group?.location_required == 1;
            } else {
                return false;
            }
        },
        technologySelected() {
            if(this.isMounted) {
                return this.project.fg_locations.length > 0 ? this.project.fg_locations[0] : null;
            } else {
                return {};
            }
        },
        bankAccount() {
            return this.project.client.user.personal.bank_accounts.length > 0;
        },
        hasDocument() {
            return this.document != null;
        },
        personalDataIsCompleted() {
            return this.bankAccount && this.hasDocument && this.validateIdentity(this.project);
        },
        fgLocation() {
            return this.project.project_fg_locations ? this.project.project_fg_locations[0] : null;
        },
        hasLocationDocument() {
            if(this.isEcotechnologiesLoan) {
                return this.projectDocument != null;
            } else {
                return true;
            }
        },
        needGuarantee() {
            return this.project.finance.guarantee_required == 1;
        },
        hasIncomeSources() {
            return this.project.client.user.personal.p_p_income_sources.length > 0;
        },
        hasIncomeSourceProofs() {
            if(this.hasIncomeSources) {
                return this.project.client.user.personal.p_p_income_sources[0].income_sources_proofs.length > 0;
            }
            return false;
        },
        guarantee() {
            return this.project.finance.guarantee.guarantee_profile;
        },
        guaranteeHasIdentity() {
            return this.guarantee.personal.identity_verifications.length > 0;
        },
        guaranteeHasAlreadyDocument() {
            return this.guaranteeDocument && this.guaranteeDocument.id;
        },
        guaranteeOnboardingStep()
        {
            return this.guarantee.guarantee_profile_step;
        },
        isGuaranteeInLastStep()
        {
            return this.guaranteeOnboardingStep == "os_pf_identity_verification";
        },
        client()
        {
            return this.project.client;
        },
        user_copy() {
            return this.client.user;
        },
        personal() {
            return this.user_copy.personal;
        },
        showSyntageStep() {
            if(this.personal.tax_profile == 1) {
                if(this.personal.last_sat_profile != null) {
                    if(this.personal.last_sat_profile.credential_status == "invalid" || this.personal.last_sat_profile.credential_status == "error") {
                        return true;
                    } else {
                        return false; // Caso valido.
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        hasDocumentationCompletedDate()
        {
            return this.project.documentation_complete_date != null;
        }
    },
    methods: {
        async getProjectData() {
            await this.$emit('saved', 1);
        },
        async getDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${DOCUMENT_REQUIRED_ID}/${this.UserId}`);
                const responseGuarantee = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${PF_GUARANTEE_ADDRESS_PROOF_ID}/${this.UserId}`);
                this.document = response.data;
                this.guaranteeDocument = responseGuarantee.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async getProjectDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/project-document-data/${DOCUMENT_PROJECT_REQUIRED_ID}/${this.project.id}`);
                this.projectDocument = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        validateIdentity(project) {
            let profile = project.client.user.personal;
            const identityData = profile.identity_verifications;
            return identityData.length > 0;
        },
        async goContinue() {
            try {
                this.showLoading(true);
                const res = await axios.put(`/api/loan-onboarding/put/${this.project.id}/pf/update-onboarding-documentation`);
                await this.$emit("updated", 1);
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
                console.log(error);
                this.failedOperationNotif();
            }
        },
        async goBack() {
            this.menuOption = 0;
            await this.$emit('saved', 1);
            // this.$emit('updated');
        },

        // VALIDACIONES
        getApplicantData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            // console.log(this.bankAccount, this.hasDocument, this.hasIncomeSourceProofs, this.validateIdentity(this.project));

            if(this.bankAccount && this.hasDocument && this.hasIncomeSourceProofs && this.validateIdentity(this.project) && !this.showSyntageStep) {
                if(this.getBankAccountData().status == "Pendiente"
                    || this.getAddressData().status == "Pendiente"
                    || this.getIdentityData().status == "Pendiente"
                    || this.getProofsData().status == "Pendiente") {
                    data.status = "Pendiente";
                    data.color = "warning";
                } else if (
                    this.getBankAccountData().status == "Completado"
                    && this.getAddressData().status == "Completado"
                    && this.getIdentityData().status == "Completado"
                    && this.getProofsData().status == "Completado"
                ) {
                    data.status = "Completado";
                    data.color = "success";
                } else if (
                    this.getBankAccountData().status == "Cargado"
                    || this.getAddressData().status == "Cargado"
                    || this.getIdentityData().status == "Cargado"
                    || this.getProofsData().status == "Cargado"
                ) {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;

        },
        getBankAccountData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            if(this.bankAccount) {
                const bankAccount = this.project.client.user.personal.bank_accounts[0];
                if(bankAccount.verified == "verified" || bankAccount.verified == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(bankAccount.verified == "not_verified" || bankAccount.verified == 0) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "verified_requested" || bankAccount.verified == 2) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "rejected" || bankAccount.verified == 3) {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else if(bankAccount.verified == "cep_requested" || bankAccount.verified == 2) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "unverified") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        },
        getAddressData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasDocument) {
                if(this.document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getIdentityData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            if(this.validateIdentity(this.project)) {
                const identity = this.project.client.user.personal.identity_verifications[0];
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(identity.identity_status == 2) {
                    data.status = "En revisión";
                    data.color = "success";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        },
        getProofsData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasIncomeSourceProofs) {
                const proofs = this.project.client.user.personal.p_p_income_sources[0].income_sources_proofs;
                let isVerified = [];
                let isPending = [];
                let isRejected = [];
                for (let index = 0; index < proofs.length; index++) {
                    const element = proofs[index];
                    const document = element.document_file;

                    if(document) {
                        if(document.is_verified == "verificado") {
                            isVerified.push(document);
                        } else if(document.is_verified == "rechazado") {
                            isRejected.push(document);
                        } else if(document.is_verified == "sin verificar") {
                            isPending.push(document);
                        }
                    } else {
                        isPending.push(element);
                    }
                }

                if(isVerified.length > 2) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(isRejected.length > 2) {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(isPending.length > 1) {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }


            return data;
        },
        getLocationData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasLocationDocument) {
                if(this.projectDocument.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.projectDocument.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.projectDocument.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getGuaranteeData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.guaranteeHasAlreadyDocument && this.guaranteeHasIdentity) {
                if(
                    this.getGuaranteeAddressData().status == "Pendiente" || this.getGuaranteeIdentityData().status == "Pendiente"
                ) {
                    data.status = "Pendiente";
                    data.color = "warning";
                } else if (
                    this.getGuaranteeAddressData().status == "Completado" && this.getGuaranteeIdentityData().status == "Completado" && this.getCDCStatus().status == "Completado"
                ) {
                    data.status = "Completado";
                    data.color = "success";
                } else if (
                    this.getGuaranteeAddressData().status == "Cargado" || this.getGuaranteeIdentityData().status == "Cargado"
                ) {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        },
        getGuaranteeAddressData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.guaranteeHasAlreadyDocument) {
                if(this.guaranteeDocument.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.guaranteeDocument.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.guaranteeDocument.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getGuaranteeIdentityData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.guaranteeHasIdentity) {
                const identity = this.guarantee.personal.identity_verifications[0];
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(identity.identity_status == 2) {
                    data.status = "En revisión";
                    data.color = "success";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        },
        getCDCStatus() {
            let data = {
                status: "Enviar recordatorio",
                color: "warning"
            };

            if(this.guarantee.personal.last_credit_request != null) {
                data.status = "Completado";
                data.color = "success";
            }

            return data;
        },
        getIcon(icon) {
            const icons = {
                "04": "04_search_clock_icon.png"
            };

            return `https://cdn.redgirasol.com/suppliers/resources/icons/${icons[icon] || ""}`;
        },
    }
}
</script>