<template>
    <div id="loan-offer" class="center">
        <div>
            <!-- <div class="vx-row justify-center">
                <div class="vx-col w-full">
                    <h2>¡Felicidades, tienes un crédito aprobado!</h2>
                    <p>Puedes ver los detalles de este crédito a continuación.</p>
                    <vs-divider></vs-divider>
                </div>
            </div> -->
            <div class="vx-row justify-center">
                <div class="vx-col sm:w-full md:w-10/12 lg:w-1/2 xxl:w-1/3 vx-card pt-6 offer-padding">
                    <img :src="getIcon" width="10%" />
                    <h1 class="extrabold-title black mb-12 mt-3 title-offer">
                        Crédito aprobado
                    </h1 class="bold">
                    <div class="amount-detail mb-base">
                        <div class="flex justify-between mb-4">
                            <h4 class="text-align-left title-option ">Monto aprobado:</h4>
                            <h1>{{ finance.financed_amount != null ? explicitMoneyFormat(finance.financed_amount) : "--" }}</h1>
                        </div>
                        <div class="flex justify-between mb-4">
                            <h4 class="text-align-left">Comisión de pre-originación:</h4>
                            <h3>{{ finance.openning_comission != null ? explicitMoneyFormat(finance.openning_comission) : "--" }}</h3>
                        </div>
                        <div class="flex justify-between mb-base">
                            <h4 class="text-align-left">Total a recibir:</h4>
                            <h3>{{ currentAmount }}</h3>
                        </div>
                    </div>
                    <div class="mb-base">
                        <div class="flex justify-between mb-4">
                            <h4 class="text-align-left">Plazo meses:</h4>
                            <h3>{{ finance.term != null ? finance.term : "--" }} meses</h3>
                        </div>
                        <!-- v-if="showSupplierData" -->
                        <div v-if="showSupplierData && finance.delivery_term > 0"  class="flex justify-between mb-4">
                            <h6 class="text-align-left">Pago entrega: 
                                <small class="font-regular"><br>primeros {{ finance.delivery_term }} meses</small>
                            </h6>
                            <h3>{{ finance.symbolic_payment != null ? explicitMoneyFormat(finance.symbolic_payment) : "--" }}</h3>
                        </div>
                        <div class="flex justify-between mb-base">
                            <h4 class="text-align-left">Pago mensual:
                                <small v-if="showSupplierData && finance.delivery_term > 0" class="font-regular"><br>resto del plazo</small>
                            </h4>
                            <h3>{{ finance.monthly_payment != null ? explicitMoneyFormat(finance.monthly_payment) : "--" }}</h3>
                        </div>
                        <div class="mt-4 mb-base">
                            <small class="bold black">
                                Tasa de interés anual fija {{ finance.annual_interest_percentage != null ? (finance.annual_interest_percentage * 100).toFixed(2) + "%" : "--" }}
                                CAT {{ finance.cat }}% sin IVA.
                            </small>
                            <br>
                            <small class="black">
                                Comisión por administración: 1% mensual sobre el saldo insoluto.
                            </small>
                        </div>
                        <div class="text-align-left mb-base">
                            <p @click.stop="showPaymentPlan = true" class="mb-2 mt-2 bold black clickable-img">
                                <u>Ver plan de pagos</u>
                            </p>
                            <p v-if="showSupplierData" @click.stop="showSupplierInfo = true" class="mb-4 mt-2 bold black clickable-img">
                                <u>Ver info. de proveedor</u>
                            </p>
                        </div>
                        <div>
                            <vs-button size="large" color="dark" :disabled="!hasOffer"
                                @click.stop="postAcceptLoan()">Aceptar crédito</vs-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="vx-row m-2 p-2">
                <div class="vx-col sm:w-full md:w-4/5 lg:w-3/5 address-card p-6">
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-1/3">
                            <div class="flex mb-base">
                                <img :src="moneyBag" width="15%" />
                                <p class="mb-2 mt-4 ml-4 text-2xl bold black">Crédito {{ loanType }}</p>
                            </div>
                            <div class="text-lg">
                                <p class="mb-2">Monto aprobado:</p>
                                <h1 class="mb-6">{{ finance.financed_amount != null ? mxnFormat(finance.financed_amount) : "--" }}</h1>
                                <p class="mb-2">
                                    Plazo: <br>
                                    <span class="bold black">{{ finance.term != null ? finance.term : "--" }}
                                        meses</span>
                                </p>
                                <p class="mb-2">
                                    Tasa de interés anual*: <br>
                                    <span class="black bold">{{ finance.annual_interest_percentage != null ? (finance.annual_interest_percentage * 100).toFixed(2) + "%" : "--" }}</span>
                                </p>
                                <p class="mb-2">
                                    Pago mensual: <br>
                                    <span class="black bold">
                                        {{ finance.monthly_payment != null ? mxnFormat(finance.monthly_payment) : "--" }}
                                    </span>
                                </p>
                            </div>
                            <small class="black">*Tasa de interés anual fija {{ (finance.annual_interest_percentage * 100).toFixed(2) }}%.
                                CAT {{ finance.cat }}% sin IVA.
                            </small>
                        </div>
                        <div class="vx-col sm:w-full md:w-2/3 border-left pt-2">
                            <p class="mb-1 mt-2 bold text-lg">Detalle</p>
                            <div class="detail">
                                <p class="mb-1 mt-2">Comisión por manejo de cuenta: </p>
                                <p>{{ mxnFormat(administrationCommission) }}</p>
                            </div>
                            <div class="detail">
                                <p class="mb-1 mt-2">IVA: </p>
                                <p>{{ mxnFormat(administrationCommissionTax) }}</p>
                            </div>
                            <div class="detail">
                                <p class="mb-1 mt-2">Comisión por apertura (1%): </p>
                                <p>{{ mxnFormat(openningCommission) }}</p>
                            </div>
                            <div class="detail">
                                <p class="mb-1 mt-2">Comisión por disposición (4%): </p>
                                <p>{{ mxnFormat(dispositionCommission) }}</p>
                            </div>
                            <div class="detail bold black mb-base">
                                <p class="mb-1 mt-2">Monto total que recibirás: </p>
                                <p>{{ currentAmount }}</p>
                            </div>
                            <p @click.stop="showPaymentPlan = true" class="mb-2 mt-2 bold text-primary clickable-img">
                                <u>Ver plan de pagos</u>
                            </p>
                            <p v-if="showSupplierData" @click.stop="showSupplierInfo = true" class="mb-4 mt-2 bold text-primary clickable-img">
                                <u>Ver info. de proveedor</u>
                            </p>
                        </div>
                        <div class="vx-col w-full text-align-last-right">
                            <vs-button color="dark" :disabled="!hasOffer"
                                @click.stop="postAcceptLoan()">Aceptar crédito</vs-button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- LISTA DE PAGOS -->
        <vs-popup :active.sync="showPaymentPlan" title="Plan de pagos">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h5 class="mb-4">Aquí tienes el desglose de pagos en tu crédito:</h5>
                    <vs-table class="mb-base" id="payments-table" :data="onboardingStepData.amortizations" stripe noDataText="No hay registros disponibles">
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
        
                        <template slot-scope="{data}">
                            <tbody>
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td>
                                    {{tr.number}}
                                </vs-td>
                                <!-- <vs-td>
                                    {{tr.payment_date}}
                                </vs-td> -->
                                <vs-td>${{format_price(tr.payment,2)}}</vs-td>
                                <vs-td>${{format_price(tr.initial_debt,2)}}</vs-td>
                                <vs-td>${{format_price(tr.amortization,2)}}</vs-td>
                                <vs-td>${{format_price(tr.interest,2)}}</vs-td>
                                <vs-td>${{format_price(tr.client_comission,2)}}</vs-td>
                                <vs-td>${{format_price(tr.tax + tr.client_commission_tax,2)}}</vs-td>
                                <vs-td>${{format_price(tr.final_debt,2)}}</vs-td>
                            </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-popup>

        <!-- INFORMACIÓN DEL PROVEEDOR -->
        <vs-popup v-if="supplier" :active.sync="showSupplierInfo" title="Detalle del proveedor">
            <div class="vx-row address-card m-10">
                <div class="vx-col w-full">
                    <p class="mb-2">Tu proveedor asignado es <span class="bold">{{ supplier.tradename }}</span> y estamos trabajando en conjunto en la gestión de tu proyecto.</p>
                    <!-- <h4 class="mb-1">{{ supplier.tradename }}</h4> -->
                    <p><span class="bold black">Responsable en {{ supplier.tradename }}:</span></p>
                    <ul class="supplier-contact mb-4">
                        <li>{{ getSupplierContact.title }}</li>
                        <li>{{ getSupplierContact.name }}</li>
                        <li>{{ getSupplierContact.email }}</li>
                        <li>{{ phoneFormat(getSupplierContact.phone) }}</li>
                    </ul>
                    <vs-divider></vs-divider>
                    <div class="mb-4">
                        <div v-if="isLoanRequestCompleted">
                            <h4 class="mb-1">Detalle de tu solicitud</h4>
                            <ul class="supplier-contact">
                                <li>{{ perojectFgLocation.family_group.name }}</li>
                                <li>{{ perojectFgLocation.location.address.full_address }}</li>
                            </ul>
                        </div>

                        <div v-else>
                            <p class="bold black">El proveedor aún está completando la información técnica.</p>
                        </div>
                    </div>
                </div>
            </div>
        </vs-popup>

    </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import AesthVueRangeInput from "aesth-vue-range-input";
import { VMoney } from 'v-money';

const LOAN_TYPE_ECOTECNOLOGIAS = 1;
const LOAN_TYPE_PERSONAL = 5;
const LOAN_TYPE_PYME = 4;

const termsOptions = [
    { label: "6", value: "6", desc: null },
    { label: "12", value: "12", desc: null },
    { label: "24", value: "24", desc: null },
    { label: "36", value: "36", desc: null },
    { label: "48", value: "48", desc: null },
    { label: "60", value: "60", desc: null },
];

const columns = [
    { title: "MES", key: "month", sortable: true }
    // , { title: "FECHA DE PAGO", key: "payment_date", sortable: true }
    , { title: "PAGO", key: "payment", sortable: true }
    , { title: "SALDO INICIAL", key: "initial_debt", sortable: false }
    , { title: "PAGO A CAPITAL", key: "amortization", sortable: false }
    , { title: "INTERESES", key: "interest", sortable: false }
    , { title: "COMISION", key: "comission", sortable: false }
    , { title: "IVA", key: "tax", sortable: false }
    , { title: "SALDO FINAL", key: "final_debt", sortable: false }
];

export default {
    name: "LoanOffer",
    props: ["onboardingStepData", "isMoral", "isCustomizing"],
    mixins: [formatHelper],
    directives: { money: VMoney },
    components: {
        AesthVueRangeInput
    },
    data: () => ({
        moneyBag: require("@assets/images/loan-onboarding/money-bag-icon.svg"),
        solarPanel: require("@assets/images/loan-onboarding/solar-panel-icon.svg"),
        carIcon: require("@assets/images/loan-onboarding/car-icon.svg"),
        requiredPlaceholder: "(Requerido)",
        requiredRules: "required",
        loanTerm: null,
        newAmount: 0,
        newAmountMoney: null,
        money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false
        },
        termsOptions: termsOptions,
        columns: columns,
        showPaymentPlan: false,
        showSupplierInfo: false
    }),
    watch: {
        newAmount: function (val) {
            this.newAmountMoney = this.costFormat(val);
        }
    },
    created() {
        this.newAmount = this.finance.financed_amount;
        this.newAmountMoney = this.costFormat(this.finance.financed_amount);
        this.loanTerm = this.finance.term;
    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        finance() {
            return this.base.finance;
        },
        isEcotechnologiesLoan() {
            return this.finance.loan_type === LOAN_TYPE_ECOTECNOLOGIAS;
        },
        hasOffer() {
            return this.base.loan_proposal_timestamp != null && this.base.finance.credit_acceptance_date == null;
        },
        loanType() {
            const loanType = this.base.finance.loan_type;
            let loanTypeText = "";
            if (loanType === LOAN_TYPE_ECOTECNOLOGIAS) {
                if(this.showSupplierData) {
                    loanTypeText = "Solar";
                } else {
                    loanTypeText = "Automotriz";
                }
            } else if (loanType === LOAN_TYPE_PERSONAL) {
                loanTypeText = "Personal";
            } else if (loanType === LOAN_TYPE_PYME) {
                loanTypeText = "PYME";
            }

            return loanTypeText;
        },
        getIcon() {
            const loanType = this.base.finance.loan_type;
            let icon = "";
            if (loanType === LOAN_TYPE_ECOTECNOLOGIAS) {
                if(this.showSupplierData) {
                    icon = this.solarPanel;
                } else {
                    icon = this.carIcon;
                }
            } else {
                icon = this.moneyBag;
            }

            return icon;
        },
        openningCommission() {
            return ((this.newAmount * 0.01).toFixed(2));
        },
        dispositionCommission() {
            return ((this.newAmount * 0.04).toFixed(2));
        },
        administrationCommission() {
            if(this.onboardingStepData.amortizations.length > 0) {
                return ((this.onboardingStepData.amortizations[0].client_comission).toFixed(2));
            } else {
                return 0;
            }
        },
        administrationCommissionTax() {
            if(this.onboardingStepData.amortizations.length > 0) {
                return ((this.onboardingStepData.amortizations[0].client_commission_tax).toFixed(2));
            } else {

            }
        },
        currentAmount() {
            return this.explicitMoneyFormat(this.newAmount - this.finance.openning_comission);
        },
        supplier() {
            return this.base.supplier;
        },
        supplier_agent() {
            return this.base.agent;
        },
        perojectFgLocation() {
            return this.base.project_fg_locations[0];
        },
        getSupplierContact() {
            const supplierContact = this.base.supplier_agent_id != null ? this.supplier_agent : this.supplier.contacts[0];
            if (this.base.supplier_agent_id != null) {
                return {
                    name: supplierContact.name,
                    phone: supplierContact.phone?.phone,
                    email: supplierContact.user.email,
                    title: "Agente"
                };
            } else {
                return {
                    name: supplierContact.name,
                    phone: supplierContact.phone,
                    email: supplierContact.email,
                    title: "Director comercial"
                };
            }
        },
        isLoanRequestCompleted() {
            return this.base.documentation_complete_date != null;
        },
        showSupplierData() {
            if(this.base.supplier_id != null) {
                if (this.isEcotechnologiesLoan) {
                    let familyGroup = this.perojectFgLocation.family_group_id;
                    if (familyGroup == 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
    methods: {
        async postAcceptLoan() {
            try {
                this.showLoading(true, "Aceptando crédito...");
                const payload = {
                    finance_data: {
                        new_financed_amount: this.newAmount,
                        new_term: this.loanTerm
                    }
                };
                const res = await axios.post(`/api/loan-onboarding/post/${this.base.id}/accept-loan`, payload);
                if (res.status === 200) {
                    this.$emit('updated', 1);
                } else {
                    this.failedOperationNotif(null, res.data)
                }
                this.showLoading(false);
                this.saveSuccessNotif("Crédito aceptado", "Tu crédito ha sido aceptado con éxito.");
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif(null, "No hemos podido continuar con el proceso en éste momento, por favor ponte en contacto con nuestro equipo a través del chat.")
                console.log(error);
            }
        },
        customizeLoan() {
            this.$emit('updated', 1);
        },
        costFormat(val) {
            return this.mxnFormat(val);
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

    .vs-popup--header {
        background-color: #28DE18;
        color: #fff !important;
    }
        .vs-popup--header .vs-popup--title h3 {
            color: #fff !important;
        }

    .wizard-card-footer {
        display: none;
    }

    .border-left {
        border-left: solid 1px #f2f2f2;
    }

    .range-tags {
        display: flex !important;
        justify-content: space-between;
    }

    .custom-range input::before {
        top: 5px !important;
        background-color: #28DE18;
    }

    input {
        height: auto !important;
    }


    .mt-fix {
        margin-top: -1.5rem;
    }

    .supplier-contact {
        list-style: disc;
        margin-left: 2rem;
        margin-bottom: 1rem;
        line-height: 1.5rem;
    }

    .offer-padding {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    @media (max-width: 550px){
        .border-left {
            border-left: none;
            border-top: solid 1px #f2f2f2;
            margin-top: 1rem;
        }
        
        .detail {
            display: block !important;
            border-bottom: none;
        }

        .title-offer {
            font-size: 1.875rem !important;
        }

        .center {
            margin-right: 0px;
        }

        #loan-offer {
            padding: 1.2rem;
        }

        .offer-padding {
            padding-left: 0.7rem !important;
            padding-right: 0.7rem !important;
        }
    }

    .detail { 
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f3f3f3;
    }

    .amount-detail {
        border-bottom: 2px solid #28DE18;
    }

    .text-align-left {
        text-align: left;
    }

</style>