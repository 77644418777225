<template>
    <div id="bankaccounts-clabe">
        <div class="vx-row mb-2">
            <div class="vx-col w-full mb-2">
                <h2>Cuenta CLABE</h2>
                <p>Ingresa tu cuenta CLABE</p>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col sm:w-full mb-6">
                <div class="document-detail">
                    <p class="bold black text-lg mb-2">Antes de compartirnos la cuenta:</p>
                    <ul class="check-ul">
                        <li class="black">Debe estar <span class="bold">a tu nombre</span>.</li>
                        <li class="black">Aquí <span class="bold">depositaremos</span> tu crédito.</li>
                        <li class="black">RedGirasol depositará <span class="bold">un centavo</span> para validar la <span class="bold">autenticidad, propiedad y recepción de transferencias</span> de tu cuenta.</li>
                    </ul>
                </div>
            </div>
            <div :class="colClass">
                <vs-input
                    @input="(val) => (clabe = clabe.toUpperCase())"
                    class="w-full"
                    label="CLABE"
                    name="clabe"
                    v-validate="requiredRules"
                    v-model.trim="clabe"
                    :danger="hasError('clabe')"
                    :danger-text="errorText('clabe')"
                    :success="isSuccess('clabe')"
                    icon-pack="feather"
                    :disabled="hasBankAccount"
                    :placeholder="requiredPlaceholder"
                />
                <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mb-3 mt-4" color="danger">
                    <span>{{errorMssg}}</span>
                </vs-alert>
            </div>
        </div>
        <div v-if="hasBankAccount" class="vx-row">
            <div :class="colClass">
                <vs-input
                    @input="(val) => (clabe = clabe.toUpperCase())"
                    class="w-full mb-4"
                    label="Banco"
                    name="clabe"
                    v-validate="requiredRules"
                    v-model.trim="bank_account.bank.name"
                    :danger="hasError('clabe')"
                    :danger-text="errorText('clabe')"
                    :success="isSuccess('clabe')"
                    icon-pack="feather"
                    :disabled="hasBankAccount"
                    :placeholder="requiredPlaceholder"
                />
                <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mb-3" color="danger">
                    <span>{{errorMssg}}</span>
                </vs-alert>
            </div>
        </div>
        <div class="vx-row mt-4">
            <div class="vx-col sm:w-1/2 md:w-1/12 lg:w-1/12">
                <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
            </div>
            <div v-if="!hasBankAccount" class="vx-col sm:w-1/2 md:w-2/12 lg:w-2/12">
                <vs-button :disabled="clabe == null" class="w-full" size="sm" color="black" @click.stop="saveDataForm()">Continuar</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApplicantBusinessCLABE",
    props: ["onboardingStepData", "isPersonal"],
    data: () => ({
        isMounted: false,
        document: null,
        requiredRules: "required|digits:18",
        errorMssg: null,
        colClass: "vx-col sm:w-full md:w-1/4 mb-2",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        clabe: null
    }),
    created() {
        this.clabe = this.bank_account ? this.bank_account.clabe : null;

        if(this.bankAccountStatus == 'rejected') {
            this.errorMssg = "Tu cuenta ha sido rechazada. Por favor, ingresa una cuenta válida.";
        }

        this.isMounted = true;
    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        business() {
            return this.base.client.user.business;
        },
        personal() {
            return this.base.client.user.personal;
        },
        bank_account() {
            if(this.isPersonal) {
                return this.personal.bank_accounts.length > 0 ? this.personal.bank_accounts[0] : null;
            } else {
                return this.business.bank_accounts.length > 0 ? this.business.bank_accounts[0] : null;
            }
        },
        hasBankAccount() {
            return this.bank_account != null && this.bankAccountStatus != 'rejected';
        },
        bankAccountStatus() {
            return this.bank_account.verified;
        }
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if(this.hasBankAccount) {
                this.$emit("updated", this.bank_account);
                return;

            }
            if (!(await this.validateForm())) {
                return;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    clabe: this.clabe,
                    bank_account_id: this.bank_account ? this.bank_account.id : null
                };
                const res = await axios.post(
                    `/api/loan-onboarding/post/${this.UserId}/update-bankaccount`,
                    payload
                );

                if(res.status == 200) {
                    this.$emit("updated", res.data.bank_account);
                } else {
                    this.errorMssg = res.data;
                }
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                console.log(e);
                this.errorMssg = e;
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        goBack() {
            this.$emit("on-back");
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    },
}
</script>