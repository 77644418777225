<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">Detalles de empleo.</h3>
                <p>Favor completa la siguiente información.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row">
                        <div :class="colClass">
                            <label class="vs-input--label">Ocupación</label>
                            <v-select
                                name="personal_occupation_id" 
                                v-validate="requiredRules"
                                :danger="hasError('personal_occupation_id')"
                                :danger-text="errorText('personal_occupation_id')"
                                :success="isSuccess('personal_occupation_id')"
                                :class="{
                                    'has-error': hasError('personal_occupation_id'),
                                    'is-success': isSuccess('personal_occupation_id'),
                                }"
                                placeholder="Selecciona una opción."
                                v-model.lazy="personal_occupation_id"
                                :clearable="false"
                                :searchable="true"
                                :options="personalActivities()"
                                :reduce="label => label.id"
                                class="mr-2 w-full">
                            </v-select>
                            <v-error v-if="hasError('personal_occupation_id')" :error="errorText('personal_occupation_id')" />
                        </div>
                        <div :class="colClass">
                            <label class="vs-input--label">Sector</label>
                            <v-select
                                name="personal_business_activity_category_id" 
                                v-validate="requiredRules"
                                :danger="hasError('personal_business_activity_category_id')"
                                :danger-text="errorText('personal_business_activity_category_id')"
                                :success="isSuccess('personal_business_activity_category_id')"
                                :class="{
                                    'has-error': hasError('personal_business_activity_category_id'),
                                    'is-success': isSuccess('personal_business_activity_category_id'),
                                }"
                                placeholder="Selecciona una opción."
                                v-model.lazy="personal_business_activity_category_id"
                                :clearable="false"
                                :searchable="true"
                                :options="personalBusinessActivitiesCategoriesFormat()"
                                :reduce="label => label.id"
                                v-on:change="set_personal_business_activities()"
                                class="mr-2 w-full">
                            </v-select>
                            <v-error v-if="hasError('personal_business_activity_category_id')" :error="errorText('personal_business_activity_category_id')" />
                        </div>
                        <div :class="colClass">
                            <label class="vs-input--label">Actividad principal</label>
                            <v-select
                                name="personal_business_activity_id" 
                                v-validate="requiredRules"
                                :danger="hasError('personal_business_activity_id')"
                                :danger-text="errorText('personal_business_activity_id')"
                                :success="isSuccess('personal_business_activity_id')"
                                :class="{
                                    'has-error': hasError('personal_business_activity_id'),
                                    'is-success': isSuccess('personal_business_activity_id'),
                                }"
                                placeholder="Selecciona una opción."
                                v-model.lazy="personal_business_activity_id"
                                :clearable="false"
                                :searchable="true"
                                :options="businessActivitiesFormat()"
                                :reduce="label => label.id"
                                :disabled="personal_business_activity_category_id == null"
                                class="mr-2 w-full">
                            </v-select>
                            <v-error v-if="hasError('personal_business_activity_id')" :error="errorText('personal_business_activity_id')" />
                        </div>
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSection()">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const jobOptions = [
    { label: "Puestos directivos", value: 2 },
    { label: "Puestos de mando medio", value: 1 },
    { label: "Puestos de supervisión, profesionales y técnicos", value: 3 },
    { label: "Puestos operativos y de apoyo", value: 4 },
]
const boolOptions = [
    { label: "Si", value: 1 },
    { label: "No", value: 0 }
]

export default {
    name: "PersonalOccupationInformation",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: {
        project: Object
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col sm:w-full md:w-5/12 mb-5",
        requiredRules: "required",
        errorMssg: null,
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        jobOptions: jobOptions,
        boolOptions: boolOptions,
        collections: {
            personal_occupations: [],
            business_activities: [],
            personal_business_activities_categories: []
        },
        personal_occupation_id: null,
        personal_business_activity_category_id: null,
        personal_business_activity_id: null,
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        await this.getCollections();
        this.setData();
        this.isMounted = true;
    },
    watch: {
        personal_business_activity_category_id: function (val) {
            this.set_personal_business_activities();
        },
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        personal() {
            return this.base.client.user.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        incomeSources() {
            return this.personal.p_p_income_sources[0];
        },
        personalCreditProfile() {
            return this.personal.credit_profile;
        },
        personalBusinessActivitiesFormat() {
            const collectionArray = [];
            if(this.isMounted) {
                this.collections.personal_occupations.forEach(item => {
                    if(item.id != 6 && item.id != 4 && item.id != 7 && item.id != 100 && item.id != 101) {
                        collectionArray.push({
                            id: item.id,
                            label: item.name
                        });
                    }
                });
                
            } 
            return collectionArray;
        },
    },
    methods: {
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        onSelect({ dialCode }) {
            this.payload.phoneReq.country_calling_code = `+${dialCode}`;
        },
        setData() {
            if(this.isMounted) {
                this.personal_occupation_id = this.personal.personal_occupation_id;
                // this.personal_business_activity_category_id = this.personal.personal_business_activity_category_id;
                this.personal_business_activity_id = this.personal.personal_business_activity_id;
            }
        },
        async saveSection() {
            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                this.missingFieldsNotif();
                return false;
            }

            try {
                this.showLoading(true);
                const res = await axios.post(`/api/loan-onboarding/post/${this.ApplicantId}/update-occupation`, {
                    project_id: this.base.id,
                    personal_occupation_id: this.personal_occupation_id,
                    personal_business_activity_id: this.personal_business_activity_id,
                
                });
                this.showLoading(false);
                this.saveSuccessNotif();
                await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                await this.$emit("updated", 1);
            } catch (error) {
                console.log(error);
                this.showLoading(false);
            }
        },
        async getCollections(){
            try {
                let collectionsObjects = ['personalOccupationsList', 'personalBusinessActivityCategoryList'];

                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.personal_occupations = res.data.personalOccupationsList;  
                this.collections.personal_business_activities_categories = res.data.personalBusinessActivityCategoryList;        
            }
            catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        personalBusinessActivitiesCategoriesFormat() {
            if(this.isMounted) {
                return this.collections.personal_business_activities_categories.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
            } else {
                return [];
            }
        },
        businessActivitiesFormat() {
            if(this.isMounted) {
                return this.collections.business_activities.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
            } else {
                return [];
            }
        },
        personalActivities() {
            if(this.isMounted) {
                const options = [];
                this.collections.personal_occupations.forEach(item => {
                    if(item.id != 6 && item.id != 4 && item.id != 7) {
                        options.push({
                            id: item.id,
                            label: item.name
                        });
                    }
                });
                return options;
            } else {
                return [];
            }
        },
        set_personal_business_activities_category() {
            if (this.personal.personal_business_activity_id == null || this.personal.personal_business_activity_id == "") {
                return;
            }
            let match = null;
            let categorySelected = null;
            for (const category of this.collections.personal_business_activities_categories) {
                match = category.activities.find(x => x.id === this.personal.personal_business_activity_id);
                if (match != undefined) {
                    categorySelected = category;
                    break;
                }
            }
            this.collections.business_activities = categorySelected.activities;
            this.personal.personal_business_activity_category_id = categorySelected.id;
        },
        set_personal_business_activities() {
            if (this.personal_business_activity_category_id == null || this.personal_business_activity_category_id == "") {
                return;
            }
            let categorySelected = this.collections.personal_business_activities_categories.find(x => x.id === this.personal_business_activity_category_id);
            this.collections.business_activities = [];
            categorySelected.activities.forEach(opt => {
                this.collections.business_activities.push(opt); //conversion a sintaxis de vx-select
            })
            this.personal_business_activity_id = null;
        },
    }
}
</script>

<style>
.has-error .vs__dropdown-toggle {
    border-color: rgba(var(--vs-danger),1)!important;
}

.is-success .vs__dropdown-toggle {
    border-color: rgba(var(--vs-success),1)!important;
}

</style>