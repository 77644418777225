<template>
    <div id="business-address-confirmation" class="mt-base">
        <div v-if="isMounted" class="vx-row mb-base">
            <div class="vx-col w-full mb-4">
                <h2>Comprobante domicilio {{ isMoral ? 'de operación' : 'personal' }}</h2>
                <p>Ingresa el comprobante de domicilio no mayor a 3 meses, legible, sin cortes.</p>
                <vs-divider></vs-divider>
            </div>

            <div v-if="!isEdit" class="vx-col w-full mb-4">
                <h4 class="mb-4">Tu comprobante de domicilio debe coincidir con esta dirección:</h4>
                <div class="address-card sm:w-full md:w-1/3">
                    <div class="flex">
                        <feather-icon 
                            icon="MapPinIcon"
                            svgClasses="w-5 h-5 m-1 mr-2 mt-0"
                            class="cursor-pointer text-accent" />
                        <p class="bold mb-1">Dirección de domicilio</p>
                    </div>
                    <div class="flex justify-between">
                        <p class="ml-8 mr-2">{{ hasFullAddress ? address.full_address : 'Agrega tu dirección'  }}</p>
                        <vs-button size="small" type="line" @click.stop="isEdit = true">{{ hasFullAddress ? 'Editar' : 'Agregar' }}</vs-button>
                    </div>
                </div>
            </div>
            <div v-else class="vx-col w-full mb-4">
                <ApplicantOnboardingAddress  @updated="getProjectData" :onboardingStepData="base" :isMoral="isMoral" :isFromLoan="true" :isStakeholder="!isRepLegal" :isRepLegal="isRepLegal"/>
            </div>

            <vs-divider class="mt-12 mb-base"></vs-divider>
            <div class="vx-col sm:w-full md:w-10/12 mb-4">
                <h4 class="mb-4">Selección del documento</h4>
                <p class="bold black text-lg mb-4">Puede ser de cualquiera de estos servicios</p>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-1/2">
                        <div class="vx-row flex-end mb-base">
                            <div class="vx-col sm:w-1/2 md:w-1/12 center">
                                <img :src="icons['BulbIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start" />
                                <p class="mt-2">Luz</p>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/12 center phone">
                                <img :src="icons['PhoneIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start" />
                                <p class="mt-2">Teléfono</p>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/12 center">
                                <img :src="icons['DropIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start" />
                                <p class="mt-2">Agua</p>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/12 center">
                                <img :src="icons['MonitorIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start" />
                                <p class="mt-2">Cable</p>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/12 center">
                                <img :src="icons['FlameIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start" />
                                <p class="mt-2">Gas</p>
                            </div>
                        </div>
                        <div class="document-detail">
                            <p class="bold black text-lg mb-4">Antes de subir el documento verifica que el comprobante:</p>
                            <ul class="check-ul">
                                <li class="black">Tenga <span class="bold">máximo 3 meses</span> de antigüedad.</li>
                                <li class="black">Muestre la <span class="bold">calle, colonia y código postal</span>. Debe ser legible.</li>
                                <li class="black">Esté completo, sin cortes.</li>
                                <li class="black">Cumpla con formato PDF, JPG, GIF o PNG.</li>
                                <li class="black">El archivo pese máximo 10 MB.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/2">
                        <div class="centerx">
                            <vx-upload
                                class="upload-container"
                                text="Seleccione o arrastre un archivo"
                                ref="vsupload"
                                :automatic="true"
                                :action="uploadAction"
                                :data="dataForm"
                                :alreadyLoaded="hasAlreadyDocument"
                                :fileIsVerified="isVerified"
                                :fileIsRejected="isRejected"
                                :loadedFileName="documentName"
                                fileName="file"
                                :acceptFileTypes="['pdf','jpeg','jpg','png']"
                                :headers="headers"
                                @on-success="successUpload"
                                @on-invalid-extension="onInvalidExtensionEvent"
                            />
                            <div v-if="showAlerts" class="flex justify-between flex-wrap mb-4 small-flex">
                                <vs-alert v-if="onError && !fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }}</vs-alert>
                                <vs-alert v-if="onError && fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }} {{ remainMssg }}</vs-alert>
                                <vs-alert v-if="onSuccess" color="success" style="height: auto">
                                El archivo ha sido cargado correctamente{{(docSets.verification_required) ? " y será validado por el equipo de RedGirasol." : "."}}
                                </vs-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col sm:w-1/2 md:w-1/12">
                <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
            </div>
            <div class="vx-col sm:w-1/2 md:w-2/12">
                <!-- <vs-button class="w-full" size="sm" color="black">Continuar</vs-button> -->
            </div>
        </div>
    </div>
</template>

<script>
import ApplicantOnboardingAddress from '../../../onboarding/components/business/ApplicantOnboardingAddress.vue';
import VxUpload from '@upload/VxUpload';

const PF_STAKEHOLDER_ADDRESS_PROOF_DOCUMENT = "pf_stakeholder_address_proof";
const PF_STAKEHOLDER_ADDRESS_PROOF_ID = 177;
const PM_STAKEHOLDER_ADDRESS_PROOF_DOCUMENT = "pm_stakeholder_address_proof";
const PM_STAKEHOLDER_ADDRESS_PROOF_ID = 178;
const REP_LEGAL_ADDRESS_PROOF_DOCUMENT = "applicant_pm_os_address";
const REP_LEGAL_ADDRESS_PROOF_ID = 31;

export default {
    name: "ApplicantStakeholderAddress",
    props: ["onboardingStepData", "isMoral", "isRepLegal","document"],
    components: {
        ApplicantOnboardingAddress,
        VxUpload
    },
    data: () => ({
        isEdit: false,
        icons: {
            'BulbIcon' : require("@assets/images/loan-onboarding/bulb-icon.svg"),
            'PhoneIcon' : require("@assets/images/loan-onboarding/phone-icon.svg"),
            'DropIcon' : require("@assets/images/loan-onboarding/drop-icon.svg"),
            'MonitorIcon' : require("@assets/images/loan-onboarding/monitor-icon.svg"),
            'FlameIcon' : require("@assets/images/loan-onboarding/flame-icon.svg")
        },
        onError: false,
        onSuccess: false,
        hasFile: false,
        /* mensajes */
        genericError: "Ha ocurrido un error con la carga.",
        invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
        updateError: "Ha ocurrido un error al intentar actualizar",
        remainMssg: "El archivo cargado previamente seguirá activo.",
        apiErrors: "",
        isMounted: false,
        // document: null,
    }),
    mounted() {
        // this.getDocument();
        this.isMounted = true;
    },
    computed: {
        base() {
            if(this.isRepLegal) {
                return this.onboardingStepData.client;
            } else {
                return this.onboardingStepData;
            } 
        },
        address() {
            if(this.isRepLegal) {
                return this.base.user.business.personal.address;
            } else {
                if(this.isMoral) {
                    return this.base.business_profile.address;
                }
    
                return this.base.personal_profile.address;
            }
        },
        hasFullAddress() {
            return this.address.neighborhood_id != null;
        },
        headers(){
            return {
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
                "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": "Bearer " + this.currentAccessToken,
            };
        },
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        currentAccessToken(){
            return this.AccessToken;
        },
        uploadAction() {
            let url = this.apiStorageBasePrefix;
            if(this.hasAlreadyDocument) {
                return `${url}upload/user/${this.UserId}/update/${this.document.id}`;
            } else {
                return `${url}upload/user/${this.UserId}`;
            }
        },
        showAlerts(){
            return (this.onSuccess || this.onError);
        },
        hasAlreadyDocument() {
            return this.document != null && this.document.id != null;
        },
        isVerified(){
            return (this.document?.is_verified == "verificado");
        },
        isRejected(){
            return (this.document?.is_verified == "rechazado");
        },
        documentName() {
            return this.document?.storage_file;
        },
        stakeholderIsMoral() {
            return this.base.stakeholder_bp_id != null;
        },
        address_document_id() {
            if(this.isRepLegal) {
                return REP_LEGAL_ADDRESS_PROOF_ID;
            } else {
                if(this.stakeholderIsMoral) {
                    return PM_STAKEHOLDER_ADDRESS_PROOF_ID;
                } else {
                    return PF_STAKEHOLDER_ADDRESS_PROOF_ID;
                }
            }
        },
        address_document_prefix() {
            if(this.isRepLegal) {
                return REP_LEGAL_ADDRESS_PROOF_DOCUMENT;
            } else {
                if(this.stakeholderIsMoral) {
                    return PM_STAKEHOLDER_ADDRESS_PROOF_DOCUMENT;
                } else {
                    return PF_STAKEHOLDER_ADDRESS_PROOF_DOCUMENT;
                }
            }
        },
        stakeholder_id() {
            return this.base.id;
        },
        dataForm() {
            if(this.isRepLegal) {
                return {
                    file_type: this.address_document_prefix
                };
            } else {
                return {
                    file_type: this.address_document_prefix,
                    assocIndex: this.stakeholder_id
                };
            }
        }
    },
    methods: {
        async getProjectData() {
            this.isEdit = false;
            await this.$emit('updated', 1);
        },
        async goBack() {
            await this.$emit('on-back', 1);
        },
        async upload() {
            let doc = document.getElementById(";");
            const formData = new FormData();
            const data = {
                file_type: this.address_document_prefix,
                assocIndex: this.address_document_id
            };
            this.injectAccountMetadataToFormData(obj);
            for (var key in data) {
                formData.append(key, data[key]);
            }

            console.log(data);

            if(doc.files.length > 0){
                data.append("applicants_layout", doc.files[0]);
                const res = await axios.post(`${this.uploadAction()}`, data);
            }
        },
        async getDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${this.address_document_id}/${this.UserId}`);
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        successUpload() {
            this.$vs.notify({
                title: '¡Documento guardado!',
                text: 'Tu comprobante de domicilio ha sido guardado con éxito.',
                color: 'success',
                position: 'top-right'
            });
        
        },
        onInvalidExtensionEvent(){
            this.onError = true;
            this.invalidExtension = true;
        },
    }
}
</script>

<style>

.address-card {
    border-radius: 8px;
    border: 1px solid #28DE18;
    padding: 1rem;
    width: fit-content;
}

.vs-button.small:not(.includeIconOnly) {
    padding: 1px
}

.address-card {
    padding: 2rem;
    width: fit-content;

}

.center {
    text-align: -webkit-center;
    margin-right: 10px;

}

.center.phone p {
    margin-left: -1rem;
}

.flex-end {
    place-items: flex-end;
}

ul.check-ul {
    list-style: none;
    line-height: 25px;
}

    ul.check-ul li:before {
        content: '✓';
        color: #28DE18;
        margin-right: 12px;
    }
.con-input-upload {
    background-color: #f7fff6;
    width: inherit;
    margin-left: 0;
    /* width: 35rem !important; */
    font-size: 1.3rem !important;
}
</style>