<template>
  <div id="credit-score-request">
    <h4 class="mb-4">Verificación de historial crediticio</h4>
    <div class="w-full mb-6 text-justify flex">
      <vs-checkbox v-model="cdcPayload.tyc" :vs-value="1">
      </vs-checkbox>
      <span class="ml-2 mt-2">Acepto el <a href="https://www.redgirasol.com/aviso-de-privacidad" target="_blank">Aviso
          de
          Privacidad</a> y <a href="https://www.redgirasol.com/terminos-y-condiciones" target="_blank">Términos y
          Condiciones</a> y cláusulas de medios electrónicos tales como NIP.</span>
    </div>

    <div class="mb-4 mt-4">
      <h5>Autorización de Consulta.</h5>
      <div class="w-full">
        <p class="text-justify">
          Autorizo expresamente a <strong>Red Girasol S.A.P.I. de C.V. Institución de Financiamiento Colectivo</strong>,
          para llevar a cabo Investigaciones sobre mi comportamiento crediticio
          en las Sociedades de Información Crediticia (SIC) que estime conveniente. Conozco la naturaleza y alcance de
          la
          información
          que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial
          crediticio.
          Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras
          mantengamos
          relación jurídica. Acepto que este documento quede bajo propiedad de <strong>Red Girasol S.A.P.I. de C.V.
            Institución de Financiamiento Colectivo y/o SIC Consultada</strong>
          para efectos de control y cumplimiento del artículo 28 de la LRSIC.
        </p><br>

        <div class="mb-base">
          <div class="vx-row">
            <div class="vx-col w-full mb-3">
              <p>
                El teléfono celular proporcionado será verificado y no podrá ser modificado posteriormente. Si deseas
                editarlo da clic <a @click.stop="showPhoneFormPrompt = true" class="clickable-img">aquí</a>.
                <br>
                <br>
                Al solicitar el <strong>NIP</strong> recibirás un mensaje de texto (sms) al teléfono celular
                proporcionado anteriormente; puede tardar hasta 1 minuto en llegar.
                En caso de no haberlo recibido, lo puedes volver a solicitar dando clic <a
                  @click.stop="doRequestCDCNip()" class="clickable-img">aquí</a>.
              </p>

              <div v-if="showWhatsapp" class="mb-2 mt-2">
                <p class="bold mb-1">
                  ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
                </p>
                <div class="clickable-img" @click.stop="doRequestCDCNip(true)">
                  <vs-chip class="mr-2 bold">Enviar Whatsapp a {{ country_calling_code }}{{
        anonymousFormatPhone(phone) }}</vs-chip>
                </div>
                <div class="clickable-img" @click.stop="doRequestCDCNip()">
                  <vs-chip class="mr-2 bold">Enviar SMS a {{ country_calling_code }}{{
        anonymousFormatPhone(phone) }}</vs-chip>
                </div>
              </div>
              <div v-if="nipIsCreated && !showWhatsapp" class="mt-2">
                <small>Puedes solicitar un nuevo código en {{ whatsappTime }} segundos.</small>
              </div>
            </div>
            <div class="vx-col sm:w-full mb-2">
              <vs-button v-if="!nipIsCreated" @click="doRequestCDCNip()" color="primary" size="small" class="bold-btn mb-2 p-2"
                type="filled">Recibir NIP</vs-button>
            </div>
            <div class="vx-col sm:w-full mt-2 mb-3">
              <label for="code">Ingresa NIP</label>
              <vs-input type="text" v-model.lazy="cdcPayload.verification_code" name="code" maxlength="6"
                autocomplete="off" placeholder="Ingresa NIP" :disabled="!nipIsCreated" />
            </div>
            <div class="vx-col sm:w-full mb-2">
              <vs-button :disabled="btnIsDisabled" @click="doAuthorizationCDC()" color="primary" class="bold-btn mb-2"
                type="filled">Autorizo</vs-button>
            </div>
          </div>
        </div>
        <!-- <small class="mb-3">
                    *** Para enviar la solicitud, primero debes aceptar el <a href="https://www.redgirasol.com/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a> y <a href="https://www.redgirasol.com/terminos-y-condiciones" target="_blank">Términos y Condiciones</a>, así como haber autorizado la consulta.
                  </small> -->
      </div>
    </div>

    <vs-popup title="Actualización de teléfono." :active.sync="showPhoneFormPrompt" class="sm-popup header-primary">
      <!-- PHONE-FORM-DIV -->
      <div class="px-6 pt-5 pb-5">
        <!-- HEADER -->
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <p class="text-2xl card-sub-title">
              Ingresa el nuevo teléfono celular.
            </p>
          </div>
        </div>
        <vs-divider color="dark"></vs-divider>
        <!-- CONTENT -->
        <div class="main-form mt-base">
          <div class="vx-row">
            <div :class="colClass">
              <label class="vs-input--label">Teléfono celular *</label>
              <div class="flex">
                <vue-country-code name="dialCode" class="vs-custom" data-vv-name="dialCode" :enableSearchField="true"
                  searchPlaceholderText="Buscar código de país" :enabledCountryCode="true" @onSelect="onSelect"
                  :preferredCountries="['MX']" :danger="hasError('dialCode')" :danger-text="errorText('dialCode')"
                  :success="isSuccess('dialCode')">
                </vue-country-code>
                <vs-input class="w-full" label="" name="phone" type="tel" v-validate="'required|numeric'"
                  v-model.lazy="phoneData.phone" :danger="hasError('phone')" :danger-text="errorText('phone')"
                  :success="isSuccess('phone')" icon-pack="feather" placeholder="(Requerido)" />
              </div>
            </div>
          </div>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex">
                <vs-button color="dark" class="w-full" @click="showPhoneFormPrompt = false">Cancelar</vs-button>
                <vs-button class="w-full ml-2" @click="changeUserPhone">Guardar</vs-button>
              </div>
            </div>
          </div>
        </div>
        <!-- END CONTENT DIV -->
      </div>
      <!-- END PHONE-FORM-DIV -->
    </vs-popup>
  </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper.js"
import { ScoreService } from "@/services/score/ScoreService.js"

export default {
  name: "CreditScoreRequest",
  mixins: [formatHelper],
  props: ["currentUser", "isMoral", "isBusiness", "isApplicant", "projectId"],
  data() {
    return {
      cdcPayload: {
        person_type: null,
        tyc: null,
        verification_code: null

      },
      colClass: "vx-col w-full mb-5",
      showWhatsapp: false,
      whatsappTime: 30,
      nipIsCreated: false,
      showPhoneFormPrompt: false,
      phoneData: {
        country_calling_code: "52",
        phone: "",
        phone_id: null
      }
    }
  },
  async beforeMount() {
    if (this.phone != null) {
      this.phoneData.phone_id = this.phoneId;
    }
  },
  computed: {
    phone() {
      if (this.isMoral) {
        // Sí es moral, supongo que será al rep.legal
        return this.currentUser.business.personal.phone.phone;
      } else {
        // La persona física.
        return this.currentUser.personal.phone.phone;
      }
    },
    phoneId() {
      if (this.isMoral) {
        // Sí es moral, supongo que será al rep.legal
        return this.currentUser.business.personal.phone.id;
      } else {
        // La persona física.
        return this.currentUser.personal.phone.id;
      }
    },
    country_calling_code() {
      if (this.isMoral) {
        // Sí es moral, supongo que será al rep.legal
        return this.currentUser.business.personal.phone.country_calling_code;
      } else {
        // La persona física.
        return this.currentUser.personal.phone.country_calling_code;
      }
    },
    business() {
      return this.currentUser.business;
    },
    business_address() {
      return this.business.address;
    },
    personal() {
      if (this.isMoral) {
        return this.currentUser.business.personal;
      } else {
        return this.currentUser.personal
      }
    },
    getUrl() {
      if (this.isApplicant) {
        return `/api/loan-onboarding/post/applicant/cdc-request`;
      } else {
        return `/api/supplier-onboarding/post/cdc-request`;
      }
    },
    btnIsDisabled() {
      return this.cdcPayload.verification_code == null
        || this.cdcPayload.tyc == null
        || this.cdcPayload.verification_code == "";
    },
  },
  methods: {
    async doRequestCDCNip(isWhatsapp = false) {
      try {
        this.showLoading(true, "Enviando código de verificación...")
        const res = await axios.post(`/api/v2/projects/${this.UserId}/send-cdc-nip?whatsapp=${isWhatsapp}`);
        this.nipIsCreated = true;
        if (!this.showWhatsapp) {
          this.initNipCounter(30);
        }
        this.saveSuccessNotif(null, `Te hemos enviado un código por mensaje de texto al siguiente número: ${this.country_calling_code} ${this.anonymousFormatPhone(this.phone)}`);
        this.nipCounter++;
        this.showLoading(false);
      } catch (error) {
        console.log(error);
      }
    },

    initNipCounter() {
      let timer = setInterval(() => {
        --this.whatsappTime;
        if (this.whatsappTime == 0) {
          this.showWhatsapp = true;
          clearInterval(timer);
        }
      }, 1000);
    },

    async doAuthorizationCDC() {
      try {
        this.showLoading(true, "Gracias por autorizar la consulta, en breve tendremos una respuesta para ti...");
        const payload = {
          user_id: this.UserId,
          verification_code: this.cdcPayload.verification_code
        };
        if (this.projectId != null) {
          payload.project_id = this.projectId;

        }
        const res = await axios.post(`${this.getUrl}`, payload);
        if (res.data.success == false) {
          this.nipIsCreated = false;
          // console.log(res.data);
          let error = res.data.error;
          this.missingFieldsNotif(null, error);
          this.showLoading(false);
        } else {
          this.$emit("updated", 1);
          this.saveSuccessNotif();
          this.showLoading(false);
        }
      } catch (error) {
        this.showLoading(false);
        this.missingFieldsNotif(null, error.response.data.error);
      }
    },
    async requestScorePM() {
      this.showLoading(true);
      try {
        const response = await ScoreService.requestPM({
          ciudad: this.business_address.neighborhood_model.city.name,
          colonia: this.business_address.neighborhood_model.name,
          cp: this.business_address.neighborhood_model.zipcode,
          direccion: this.business_address.street,
          estado: this.business_address.neighborhood_model.city.state.cc_code_pm,
          folioOtorgante: this.ScoreIssuerKey,
          municipio: this.business_address.neighborhood_model.city.name,
          pais: this.business_address.country.cc_code,
          razonSocial: this.business.tax_name,
          rfc: this.business.rfc
        });
        this.showLoading(false);
        if (!response.error) {
          this.$emit("updated", 1);
          this.saveSuccessNotif();
        }
      } catch (error) {
        this.showLoading(false);
        this.failedOperationNotif()
        console.log(error);
      }
      this.showLoading(false);
    },
    async requestPF() {
      this.showLoading(true);
      try {
        const response = await ScoreService.requestPF({
          persona: {
            apellidoPaterno: this.personal.last_name_1,
            apellidoMaterno: this.personal.last_name_2,
            primerNombre: this.personal.first_name,
            segundoNombre: this.personal.second_name,
            fechaNacimiento: this.personal.birth_date,
            rfc: this.personal.rfc,
            nacionalidad: "MX"
          },
          domicilio: {
            direccion: this.personal.address.street,
            coloniaPoblacion: this.personal.address.neighborhood_model.name,
            delegacionMunicipio: this.personal.address.neighborhood_model.city.name,
            ciudad: this.personal.address.neighborhood_model.city.name,
            estado: this.personal.address.neighborhood_model.city.state.cc_code,
            cp: this.personal.address.neighborhood_model.zipcode
          }
        });
        this.showLoading(false);
        if (!response.error) {
          this.$emit("updated", 1);
          this.saveSuccessNotif();
        }
      } catch (error) {
        this.showLoading(false);
        this.failedOperationNotif()
        console.log(error);
      }
      this.showLoading(false);
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
    onSelect({ dialCode }) {
      this.phoneData.country_calling_code = `+${dialCode}`;
    },
    async changeUserPhone() {
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Cambiando el teléfono...");
      try {
        let payload = {
          applicant_id: this.ApplicantId,
          phone_data: this.phoneData,
        };
        await axios.put(
          "/api/applicant-onboarding/put/update-applicant-phone",
          payload
        );
        this.showLoading(false);
        this.showPhoneFormPrompt = false;
        this.saveSuccessNotif("Teléfono cambiado exitosamente");
        setTimeout(() => {
          this.$emit("updated", 1);
        }, "500");
      } catch (e) {
        this.showLoading(false);
        this.failedOperationNotif();
      }
    },
    async validateForm() {
      return this.$validator.validateAll();
    },
  }
};
</script>