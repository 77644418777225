<template>
    <div id="business-address-confirmation" class="mt-base">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h2>Dirección de operación</h2>
                <p>Confirma la información de tu negocio</p>
                <vs-divider></vs-divider>
            </div>

            <div v-if="!isEdit" class="vx-col w-1/3">
                <div class="address-card">
                    <div class="flex">
                        <feather-icon 
                            icon="MapPinIcon"
                            svgClasses="w-5 h-5 m-1 mr-2 mt-0"
                            class="cursor-pointer text-accent" />
                        <p class="bold mb-1">Dirección de operación</p>
                    </div>
                    <div class="flex">
                        <p class="ml-8">{{ business_address.full_address  }}</p>
                        <vs-button size="small" type="line" @click.stop="isEdit = true">Editar</vs-button>
                    </div>
                    <vs-divider></vs-divider>
                    <p class="ml-8"><i>Te haremos una visita para validar algunos datos.</i></p>
                </div>
            </div>
            <div v-else class="vx-col w-full">
                <ApplicantOnboardingAddress  @updated="getProjectData" :onboardingStepData="onboardingStepData.client" :isMoral="isMoral" :isFromLoan="true" />
            </div>

        </div>
        <div v-if="!isEdit" class="vx-row mt-10">
            <div class="vx-col w-full">
                <!-- BOTONES -->
                <div class="flex justify-end">
                    <vs-button class="mt-2 vs-button-dark"
                        @click="confirmAddress()">Continuar</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicantOnboardingAddress from '../../../onboarding/components/business/ApplicantOnboardingAddress.vue';
export default {
    name: "ApplicantAddress",
    props: ["onboardingStepData", "isMoral", "onboardingStepData"],
    components: {
        ApplicantOnboardingAddress
    },
    data: () => ({
        isEdit: false,
    }),
    computed: {
        business_address() {
            return this.onboardingStepData.client.user.business.address;
        },
    },
    methods: {
        async getProjectData() {
            this.isEdit = false;
            await this.$emit('updated', 1);
        },
        async confirmAddress() {
            try {
                this.showLoading(true, "Confirmando dirección...");
                const res = await axios.put(`/api/loan-onboarding/put/${this.ApplicantId}/update-onboarding-pm`, { 
                    project_id: this.onboardingStepData.id,
                });

                if (res.data == "success") {
                    await this.$emit("updated", 1);
                } else {
                    this.missingFieldsNotif();
                }
                this.showLoading(false);
            } catch (error) {
                console.log(error);
                this.failedOperationNotif();
            }
        }
    }
}
</script>

<style>

.address-card {
    border-radius: 8px;
    border: 1px solid #28DE18;
    padding: 1rem;
    width: fit-content;
    
}

.vs-button.small:not(.includeIconOnly) {
    padding: 1px
}

.wizard-card-footer {
    display: block;
}
</style>