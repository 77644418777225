<template>
    <div id="applicant-documents-control">
        <div class="vx-row">
            <div class="vx-col w-full mb-4">
                <h2>Documento de propiedad</h2>
                <p>Ingresa el documento de propiedad de la ubicación de instalación, legible, sin cortes.</p>
                <vs-divider></vs-divider>
                <div class="vx-col sm:w-full md:w-10/12 mb-4 mt-2">
                    <p class="bold black text-lg mb-4">Puede ser de cualquiera de estos servicios</p>
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-1/2">
                            <ul class="check-ul mb-base">
                                <li class="black">Predial</li>
                                <li class="black">Escritura</li>
                                <li class="black">Contrato de donación de inmueble</li>
                                <li class="black">Contrato de compraventa</li>
                                <li class="black">Carta de constancia de residencia</li>
                                <li class="black">Contrato de arrendamiento</li>
                            </ul>
                            <div class="document-detail">
                                <p class="bold black text-lg mb-4">Antes de subir el documento verifica que el comprobante:</p>
                                <ul class="check-ul">
                                    <!-- <li class="black">Tenga <span class="bold">máximo 3 meses</span> de antigüedad.</li> -->
                                    <li class="black">Muestre la <span class="bold">información del propietario</span>. Debe ser legible.</li>
                                    <li class="black">Esté completo, sin cortes.</li>
                                    <li class="black">Cumpla con formato PDF, JPG, GIF o PNG.</li>
                                    <li class="black">El archivo pese máximo 10 MB.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="vx-col sm:w-full md:w-1/2">
                            <document-upload
                                v-if="isMounted"
                                v-for="df in allDocumentFields"
                                :key="df.id"
                                :target_id="df.target_id"
                                :f="df.formField"
                                :doc="df.documentSet"
                                :inputClassExtraLarge="true"
                                :disabled="isVerified"
                                :evaluator="evaluateCondition"
                                :loadedState="df.loadedState"
                                :stickToLoadedState="true"
                                :from_project_id="projectId"
                                :editableByBackoffice="true"
                                @on-success="onDocumentUploadSuccess"
                            />
                            <!-- <div class="centerx">
                                <vx-upload
                                    class="upload-container"
                                    text="Seleccione o arrastre un archivo"
                                    ref="vsupload"
                                    :automatic="true"
                                    :action="uploadAction"
                                    :data="dataForm"
                                    :alreadyLoaded="hasAlreadyDocument"
                                    :fileIsVerified="isVerified"
                                    :fileIsRejected="isRejected"
                                    :loadedFileName="documentName"
                                    fileName="file"
                                    :acceptFileTypes="['pdf','jpeg','jpg','png']"
                                    :headers="headers"
                                    @on-success="successUpload"
                                    @on-invalid-extension="onInvalidExtensionEvent"
                                />
                                <div v-if="showAlerts" class="flex justify-between flex-wrap mb-4 small-flex">
                                    <vs-alert v-if="onError && !fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }}</vs-alert>
                                    <vs-alert v-if="onError && fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }} {{ remainMssg }}</vs-alert>
                                    <vs-alert v-if="onSuccess" color="success" style="height: auto">
                                    El archivo ha sido cargado correctamente{{(docSets.verification_required) ? " y será validado por el equipo de RedGirasol." : "."}}
                                    </vs-alert>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 md:w-1/12">
                <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
            </div>
            <div class="vx-col sm:w-1/2 md:w-2/12">
                <!-- <vs-button class="w-full" size="sm" color="black">Continuar</vs-button> -->
            </div>
        </div>
    </div>
</template>

<script>
const DOCUMENT_REQUIRED_ID = 48;
const DOCUMENT_REQUIRED_PREFIX = "project_ownership_proof";
import VxUpload from '@upload/VxUpload';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";

export default {
    name: "LocationDocument",
    mixins: [docsHelper, formHelper],
    props: {
        fgLocation: Object,
        projectId: Number|String,
        document: Object
    },
    components: {
        VxUpload
    },
    data: () => ({
        menuOption: 0,
        addressCompleted: false,
        // document: null,
        isMounted: false,
        icons: {
            'BulbIcon' : require("@assets/images/loan-onboarding/bulb-icon.svg"),
            'PhoneIcon' : require("@assets/images/loan-onboarding/phone-icon.svg"),
            'DropIcon' : require("@assets/images/loan-onboarding/drop-icon.svg"),
            'MonitorIcon' : require("@assets/images/loan-onboarding/monitor-icon.svg"),
            'FlameIcon' : require("@assets/images/loan-onboarding/flame-icon.svg")
        },
        onError: false,
        onSuccess: false,
        hasFile: false,
        /* mensajes */
        genericError: "Ha ocurrido un error con la carga.",
        invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
        updateError: "Ha ocurrido un error al intentar actualizar",
        remainMssg: "El archivo cargado previamente seguirá activo.",
        apiErrors: "",
        // document: null,
        pmSections: [37],
        pfSections: [37],
        flow_id_pf: 3,
        flow_id_pm: 3,
        validatedDocuments: [],
        allDocumentFields: [],
        documentsStates: [],
        allSections: [],
        isMounted: false,
        location_id: null
    }),
    async created() {
        this.isMounted = false;
        // await this.getDocument();
        this.location_id = this.fgLocation.location_id;
        await this.getSections();
        // this.isMounted = true;
    },
    computed: {
        headers(){
            return {
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
                "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": "Bearer " + this.currentAccessToken,
            };
        },
        // apiStorageBasePrefix(){
        //     return this.ApiDomain + "/storagev3/documents/";
        // },
        currentAccessToken(){
            return this.AccessToken;
        },
        // uploadAction() {
        //     let url = this.apiStorageBasePrefix;
        //     if(this.hasAlreadyDocument) {
        //         return `${url}upload/project/${this.projectId}/update/${this.document.id}`;
        //     } else {
        //         return `${url}upload/project/${this.projectId}`;
        //     }
        // },
        showAlerts(){
            return (this.onSuccess || this.onError);
        },
        hasAlreadyDocument() {
            return this.document != null && this.document.id != null;
        },
        isVerified(){
            return (this.document?.is_verified == "verificado");
        },
        isRejected(){
            return (this.document?.is_verified == "rechazado");
        },
        documentName() {
            return this.document?.storage_file;
        },
        dataForm() {
            return {
                file_type: DOCUMENT_REQUIRED_PREFIX,
                assocData: {
                    table : "location_document_files",
                    assoc_key : "location_id",
                    condition : "location_exists",
                    assoc_source : this.location_id,
                    neg_mssg : null,
                    multi_assoc : false
                }
            };
        },
        location_exists(){
            return this.location_id != null;
        },
        location_is_defined() {
            return this.fgLocation != null;
        },
    },
    methods: {
        async upload() {
            let doc = document.getElementById(";");
            const formData = new FormData();
            const data = this.dataForm;
            this.injectAccountMetadataToFormData(obj);
            for (var key in data) {
                formData.append(key, data[key]);
            }

            if(doc.files.length > 0){
                data.append("applicants_layout", doc.files[0]);
                // const res = await axios.post(`${this.uploadAction()}`, data);
            }
        },
        successUpload() {
            this.$vs.notify({
                title: '¡Documento guardado!',
                text: 'Tu comprobante de domicilio ha sido guardado con éxito.',
                color: 'success',
                position: 'top-right'
            });
        
        },
        onInvalidExtensionEvent(){
            this.onError = true;
            this.invalidExtension = true;
        },
        async goBack() {
            await this.$emit('on-back', 1);
        },
        async getSections() {
            try {
                this.showLoading(true);
                const sections = this.isMoral ? this.pmSections : this.pfSections;
                const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
                let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pf;
                let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
                let response2 = await axios.get(url);
                this.documentsStates = response2.data;
                this.allSections = res.data.sections;
                this.allDocumentFields = [];

                this.allSections.forEach(s => {
                    // s.containers = this.getContainersForSection(s.id);
                    s.documentFields = [];

                    s.fields.forEach(f => {
                        this.formFieldSetter(f, this);
                        if(f.doc_id != null){
                            if(s.id == 27) {
                                if(f.doc_id == 31 || f.doc_id == 26) 
                                return;
                        }

                        let ff = {formField: f, documentSet: null, target_id: null };
                        let ss = this.documentsStates.find(sid => sid.id == s.id);
                        if(ss && ss.documents){
                            let dset = ss.documents.find(d => d.id == f.doc_id);
                            if(dset){
                                ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                                ff.documentSet = dset;
                            }
                        }
                            s.documentFields.push(ff);
                            this.allDocumentFields.push(ff);
                        }
                    })
                });

                let docRes = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
                if(docRes){
                    this.allDocumentFields.forEach(f => {
                        if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
                            this.validatedDocuments.push(f.documentSet.id);
                        }
                    })
                }
                this.isMounted = true;
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
                console.log(error);
            }
            this.showLoading(false);
        },
        evaluateCondition(condition){
            // console.log(condition)
            return this.evaluateConditionBase(this, condition);
        },
        /* obtener ids para los casos correspondientes */
        docTargetEvaluator(cls, cls_2){
            if(cls == 'user'){
                if(cls_2 == 'applicant'){
                    return this.UserId;
                }
            }
            else if(cls == 'project'){
                return this.projectId;
            }
            this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
            return null;
        },
        onDocumentUploadSuccess(data){
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
        },
    }
}
</script>