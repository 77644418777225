<template>
    <div id="loan-offer" class="mt-base">
        <div v-if="menuOption == 0">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h2>Documentos requeridos</h2>
                    <p>Podrás firmar el contrato una vez que validemos la información.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <!-- {{ onboardingStepData.client.user.business.bank_accounts[0] }} -->
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 1">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Acta constitutiva</h5>
                            <p class="mb-2">Debes anexar datos de inscripción correspondiente (sellos y boletas).</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getConstitutiveActData().color">{{ getConstitutiveActData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 4">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Comprobantes de ingresos</h5>
                            <p class="mb-2">
                                Proporcionar tus comprobantes de ingresos te ayuda a obtener más fácil la aprobación de tu crédito. 
                                <!-- <br><small class="bold" v-if="docsMounted && getStatementSubtitle != ''">Documentos seleccionado:  {{ getStatementSubtitle }}</small> -->
                            </p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" color="#858891">Opcional</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 2">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Cuenta CLABE</h5>
                            <p class="mb-2">A tu nombre o de la empresa. Aquí se depositará el crédito.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getBankAccountData().color">{{ getBankAccountData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-base m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 3">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Comprobante de domicilio</h5>
                            <p class="mb-2">No mayor a 3 meses, legible, sin cortes</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="fnAddressDocument().color">{{ fnAddressDocument().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-1/12">
                    <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
                </div>
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <!-- <vs-button class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button> -->
                </div>
            </div>
        </div>
        <div v-if="menuOption == 1">
            <ApplicantBusinessConstitutiveDocument 
                v-if="isMounted" 
                :onboardingStepData="onboardingStepData" 
                :document="document"
                @on-back="onBack"
                @on-continue="onContinue"/>
        </div>
        <div v-if="menuOption == 4">
            <ApplicantBusinessFinancialStatements 
                v-if="isMounted" 
                :onboardingStepData="onboardingStepData" 
                :financialStatements="financialStatements"
                :bankStatements="bankStatements"
                :document="document"
                @on-back="onBack"
                @on-continue="onContinue"/>
        </div>
        <div v-if="menuOption == 2">
            <ApplicantBusinessCLABE 
                v-if="isMounted" 
                :onboardingStepData="onboardingStepData"
                @updated="setBankAccount"
                @on-back="onBack"
                @on-continue="onContinue"/>
        </div>
        <div v-if="menuOption == 3">
            <ApplicantAddressDocument
                :onboardingStepData="onboardingStepData"
                :document="document"
                :isMoral="true"
                @on-back="onBack"
                @on-continue="onContinue"
                @updated="getProjectData"
            />
        </div>
    </div>
</template>

<script>
import ApplicantBusinessConstitutiveDocument from './ApplicantBusinessConstitutiveDocument.vue';
import ApplicantBusinessFinancialStatements from './ApplicantBusinessFinancialStatements.vue';
import ApplicantBusinessCLABE from './ApplicantBusinessCLABE.vue';
import ApplicantAddressDocument from '../ApplicantAddressDocument.vue';

const DOCUMENT_REQUIRED_ID = 25;
const ADDRESS_DOCUMENT_REQUIRED_ID = 33;

const PM_PROOF_ID_1 = 183;
const PM_PROOF_ID_2 = 184;
const PM_PROOF_ID_3 = 185;
const PM_PROOF_ID_4 = 186;
const PM_PROOF_ID_5 = 187;
const PM_PROOF_ID_6 = 188;


export default {
    name: "ApplicantBusinessDocuments",
    props: ["onboardingStepData", "isMoral"],
    components: {
        ApplicantBusinessConstitutiveDocument,
        ApplicantBusinessCLABE,
        ApplicantAddressDocument,
        ApplicantBusinessFinancialStatements
    },
    data: () => ({
       menuOption: 0,
       isMounted: false,
       document: null,
       addressDocument: null,
       hasBankAccount: false,
       financialStatements: [],
       bankStatements: [],
       docsMounted: false
    }),
    async created() {
        this.isMounted = false;
        this.showLoading(true, "Recopilando información...")
        await this.getDocument();
        this.addressDocument = await this.getDocument(ADDRESS_DOCUMENT_REQUIRED_ID, true);
        await this.getDocumentsNeeded();
        this.isMounted = true;
        this.showLoading(false)
    },
    computed: {
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        hasAlreadyDocument() {
            return this.document && this.document.id;
        },
        hasAlreadyBankAccount() {
            return this.hasBankAccount || this.onboardingStepData.client.user.business.bank_accounts.length > 0;
        },
        hasFinancialStatements() {
            if(this.isMounted) {
                return this.financialStatements[0] != ""
                    || this.financialStatements[1] != ""
                    || this.financialStatements[2] != "";
            } else {
                return false;
            }
        },
        hasBankStatements() {
            if(this.isMounted) {
                return this.bankStatements[0] != ""
                    || this.bankStatements[1] != ""
                    || this.bankStatements[2] != "";
            } else {
                return false;
            }
        },
        getStatementSubtitle() {
            let subtitle = "";
            if(this.hasFinancialStatements) {
                subtitle += "Estados financieros";
            }

            if(this.hasFinancialStatements && this.hasBankStatements) {
                subtitle += " y ";
            }

            if(this.hasBankStatements) {
                subtitle += "Estados de cuenta";
            }

            return subtitle;
        }
    },
    methods: {
        async getDocumentsNeeded() {
            this.docsMounted = false;
            this.financialStatements[0] = await this.getProjectDocument(PM_PROOF_ID_1, true);
            this.financialStatements[1] = await this.getProjectDocument(PM_PROOF_ID_2, true);
            this.financialStatements[2] = await this.getProjectDocument(PM_PROOF_ID_3, true);
            this.bankStatements[0] = await this.getProjectDocument(PM_PROOF_ID_4, true);
            this.bankStatements[1] = await this.getProjectDocument(PM_PROOF_ID_5, true);
            this.bankStatements[2] = await this.getProjectDocument(PM_PROOF_ID_6, true);
            this.docsMounted = true;
        },
        async reloadData() {
            await this.getDocument();
            await this.getDocumentsNeeded();
        },
        async getProjectData() {
            await this.$emit('updated', 1);
        },
        goBack() {
            this.$emit('on-back');
        },
        goContinue() {
            this.$emit('on-continue');
        },
        backFromStakeholders() {
            this.menuOption = 0;
        },
        async getDocument(docId = DOCUMENT_REQUIRED_ID, returnData = false) {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${docId}/${this.UserId}`);
                if(returnData) {
                    return response.data;
                }
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async getProjectDocument(docId = DOCUMENT_REQUIRED_ID, returnData = false) {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/project-document-data/${docId}/${this.onboardingStepData.id}`);
                if(returnData) {
                    return response.data;
                }
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async onBack() {
            this.menuOption = 0;
            this.reloadData();
        },
        async onContinue() {
            this.showLoading(true, "Guardando información...");
            await this.getDocument();
            this.menuOption = 0;
            this.showLoading(false);
        },
        async setBankAccount(e) {
            this.menuOption = 0;
            this.onboardingStepData.client.user.business.bank_accounts[0] = e;
            this.hasBankAccount = true;
        },
        hasError(val) {
            return this.errors.has(val);
        },  
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },

        // VALIDACIONES 
        getBankAccountData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasAlreadyBankAccount) {
                const bankAccount = this.onboardingStepData.client.user.business.bank_accounts[0];
                if(bankAccount.verified == "verified") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(bankAccount.verified == "not_verified") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "verified_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "rejected") {
                    data.status = "Rechazada";
                    data.color = "warning";
                }  else if(bankAccount.verified == "cep_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } 
            }

            return data;
        },
        getConstitutiveActData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasAlreadyDocument) {
                if(this.document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        fnAddressDocument() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            // let doc = await this.getDocument(ADDRESS_DOCUMENT_REQUIRED_ID, true);
            if(this.addressDocument != null) {
                let docStatus = this.addressDocument.is_verified;
                if(docStatus == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(docStatus == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(docStatus == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                }
            }

            return data;
        },
        getFinancialStatmentsData() {
            let data = {
                status: "Opcional",
                color: "#858891"
            };
            if(this.docsMounted) {
                if(this.hasBankStatements) {
                    data.status = "Pendiente";
                    data.color = "warning";
                    let doc1 = this.bankStatements[0];
                    let doc2 = this.bankStatements[1];
                    let doc3 = this.bankStatements[2];
                    if(doc1 != null && doc2 != null && doc3 != null) {
                        if(doc1.is_verified == "rechazado" || doc2.is_verified == "rechazado" || doc3.is_verified == "rechazado") {
                            data.status = "Rechazado";
                            data.color = "warning";
                        } else if(doc1.is_verified == "sin verificar" && doc2.is_verified == "sin verificar" && doc3.is_verified == "sin verificar") {
                            data.status = "Cargado";
                            data.color = "success";
                        } else if(doc1.is_verified == "verificado" && doc2.is_verified == "verificado" && doc3.is_verified == "verificado") {
                            data.status = "Completado";
                            data.color = "success";
                        }
                    }
                } 
                
                if (this.hasFinancialStatements) {
                    data.status = "Pendiente";
                    data.color = "warning";
                    let doc1 = this.financialStatements[0];
                    let doc2 = this.financialStatements[1];
                    let doc3 = this.financialStatements[2];
                    if(doc1 != null && doc2 != null && doc3 != null) {
                        if(doc1.is_verified == "rechazado" || doc2.is_verified == "rechazado" || doc3.is_verified == "rechazado") {
                            data.status = "Rechazado";
                            data.color = "warning";
                        } else if(doc1.is_verified == "sin verificar" && doc2.is_verified == "sin verificar" && doc3.is_verified == "sin verificar") {
                            data.status = "Cargado";
                            data.color = "success";
                        } else if(doc1.is_verified == "verificado" && doc2.is_verified == "verificado" && doc3.is_verified == "verificado") {
                            data.status = "Completado";
                            data.color = "success";
                        }
                    }
                }
            }

            return data;
        },
    }
}
</script>
<style>

.border-bottom-green {
    border-bottom: 1px solid #A9E3A4;
    padding-bottom: 1rem;
}

</style>