<template>
    <div id="loan-address">
        <!-- <div v-if="!doReferences" class="vx-row">
            <div class="vx-col w-full mb-base">
                <h3 class="mb-1">Comprobación de datos.</h3>
                <p>Estamos procesando la información que ingresaste y te informaremos una vez que esté verificada, esto no tardará más de 5 minutos.</p>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-10/12">
                <h5 class="mb-3">
                    Recuerda tener a mano los siguientes documentos en caso de que tu solicitud esté pre-abrobada:
                </h5>
                <div class="vx-row document-list">
                    <div class="vx-col sm:w-full md:w-1/4 document-card shadow-2">
                        <feather-icon
                            icon="UserIcon"
                            svgClasses="w-10 h-10 mb-2"
                            class="primary"
                        />
                        <p class="bold black center m-0">Identificación <br>oficial</p>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/4 document-card shadow-2">
                        <feather-icon
                            icon="HomeIcon"
                            svgClasses="w-10 h-10 mb-2"
                            class="primary"
                        />
                        <p class="bold black center m-0">Comprobante <br>de domicilio</p>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/4 document-card shadow-2">
                        <feather-icon
                            icon="DollarSignIcon"
                            svgClasses="w-10 h-10 mb-2"
                            class="primary"
                        />
                        <p class="bold black center m-0">Comprobante <br>de ingreso</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div>
            <div class="vx-col w-full mb-base">
                <h3 class="mb-1">Referencias.</h3>
                <p>Como parte del proceso, necesitamos 3 referencias y 2 de ellas deben ser familiares.</p>
                <p v-if="isMounted">Las referencias tendrán que ser distintas al obligado solidario que ingresaste antes <strong>{{ guaranteeName }}</strong>.</p>
                <vs-divider></vs-divider>
            </div>
            <div v-for="(r, index) in references" class="vx-col w-1/2">
                <h5 class="mb-4">{{ getReferencesTitle(index) }} referencia.</h5>
                <div class="vx-row mb-base">
                    <div :class="colClass">
                        <vs-input
                            @input="((val) => {r.name = r.name.toUpperCase() })"
                            class="w-full"
                            label="Nombre y Apellidos"
                            type="text"
                            :placeholder="requiredPlaceholder"
                            v-model.lazy="r.name"
                            v-validate="'required'"
                            :danger="hasError('r.name_' + index)"
                            :danger-text="errorText('r.name_' + index)"
                            :success="isSuccess('r.name_' + index)"
                            :name="`r.name_${index}`"/>
                            <small></small>
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label">Celular a 10 dígitos</label>
                        <div class="flex">
                            <vue-country-code 
                                :name="`dialCode_${index}`" 
                                class="vs-custom"
                                data-vv-name="dialCode" 
                                :enableSearchField="true"
                                v-model="r.dialCode"
                                searchPlaceholderText="Buscar código de país"
                                :enabledCountryCode="true" 
                                @onSelect="onSelect(index, $event)"
                                :preferredCountries="['MX']" 
                                :danger="hasError(`dialCode_${index}`)"
                                :danger-text="errorText(`dialCode_${index}`)"
                                :success="isSuccess(`dialCode_${index}`)">
                            </vue-country-code>
                            <vs-input class="w-full" 
                                label="" 
                                :name="`r.phone_${index}`" 
                                type="tel"
                                v-validate="'required|numeric'"
                                v-model.lazy="r.phone"
                                :danger="hasError('r.phone_' + index)"
                                :danger-text="errorText('r.phone_' + index)"
                                :success="isSuccess('r.phone_' + index)" 
                                icon-pack="feather"
                                placeholder="(Requerido) Ingresa 10 dígitos" />
                        </div>
                    </div>
                    <div :class="colClass">
                        <vs-input 
                            class="w-full" 
                            label="Correo electrónico"
                            :name="`r.email_${index}`" 
                            v-validate="emailRules"
                            v-model.lazy="r.email"
                            :danger="hasError('r.email_' + index)" 
                            :danger-text="errorText('r.email_' + index)"
                            :success="isSuccess('r.email_' + index)" 
                            icon-pack="feather"
                            :placeholder="emailPlaceholder" />
                    </div>
                    <div :class="colClass">
                        <label for="" class="vs-input--label">Tipo de Relación</label>
                            <v-select
                                label="text"
                                :name="`r.relation_type_${index}`"
                                class="vs-custom w-full mb-5"
                                v-model.lazy="r.relationType"
                                v-validate="'required'"
                                :options="getOptionsForSelect"
                                :reduce="text => text.value"
                                :clearable="false"
                                :searchable="false"
                                placeholder="Seleccione una opción">
                            </v-select>
                    </div>
                </div>
            </div>

            <div class="vx-col w-1/4">
                <vs-button color="black" @click.stop="saveReferences()">Enviar referencias</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreditScoreRequest",
    props: {
        project: Object,
    },
    data: () => ({
        base: {},
        isMounted: false,
        doReferences: false,
        emailRules: 'required|email',
        emailPlaceholder: 'ejemplo@mail.com',
        requiredPlaceholder: '(Requerido)',
        colClass: 'vx-col sm:w-1/2 md:w-1/10 mb-5',
        references: [
            {
                name: null,
                phone: null,
                dialCode: null,
                email: null,
                relationType: null
            },
            {
                name: null,
                phone: null,
                dialCode: null,
                email: null,
                relationType: null
            },
            {
                name: null,
                phone: null,
                dialCode: null,
                email: null,
                relationType: null
            }
        ],
        relationTypes: [],
        getOptionsForSelect: [],
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        this.isMounted = true;
        this.doReferences = this.base.status == 3;
        this.getCollections();
        this.showCongratsMssg();
    },
    computed: {
        projectStatus() {
            return this.base.status;
        },
        isRejected() {
            return this.base.status === 9;
        },
        isPreApproved() {
            return this.base.status === 3;
        },
        finances() {
            return this.base.finance;
        },
        isLoanEcotechnologies() {
            return this.base.loanType == 1;
        },
        guarantee() {
            return this.base.finance.guarantee.guarantee_profile;
        },
        guaranteeName() {
            if(this.guarantee != null) {
                const name = this.isMoral ? this.business.name : this.personal.first_name + " " + this.personal.last_name_1;
                return `(${name})`;
            }
            return "";
        },
        personal() {
            return this.base.finance.guarantee.guarantee_profile.personal;
        },
        business() {
            return this.base.client.user.business;
        },
    },
    methods: {
        showCongratsMssg() {

            if(this.isRejected) {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: 'Solicitud no aprobada.',
                    text: `En esta ocasión no hemos podido aprobar tu solicitud, pero no te preocupes, puedes volver a intentarlo en otro momento. ¡Muchas gracias por confiar en RedGirasol!`,
                    acceptText: "Aceptar",
                    cancelText: '',
                    accept: this.goToHomePage,
                    cancel: this.goToHomePage
                });
            }

            if(this.isPreApproved) {
                let text = 'Red Girasol te asignará un Proveedor que se pondrá en contacto contigo pronto.';
                if(!this.isLoanEcotechnologies) {
                    text = 'Por favor, proporciona tus referencias personales para avanzar.';
                }

                this.$vs.dialog({
                    type: 'confirm',
                    color: 'success',
                    title: '¡Felicidades, tu solicitud ha sido pre-aprobada!',
                    text: text,
                    acceptText: "Aceptar",
                });
            }
        },
        async goToHomePage(){
            await this.$router.push({name: 'inicioSolicitante'});
        },
        async saveReferences() {
            if (!(await this.validateForm())) {
                this.missingFieldsNotif();
                return false;
            }

            if(!this.validateReferences()) {
                this.missingFieldsNotif(null, "Las referencias no pueden ser iguales.");
                return false;
            }

            if(!this.validateRelatives()) {
                this.missingFieldsNotif(null, "Dos de tus referencias deben ser de tipo familiar.");
                return false;
            }

            try {
                const payload = {
                    references: this.references,
                    is_multiple: true,
                    project_id: this.base.id
                };
                this.showLoading(true, "Guardando referencias...");
                await axios.post(`/api/v1/applicant/${this.ApplicantId}/addReference`, payload);
                await axios.post(`/api/loan-onboarding/post/${this.base.id}/references-step`);
                this.showLoading(false);
                // this.$vs.dialog({
                //     color: 'success',
                //     title: '¡Felicidades!',
                //     text: `Ahora tu solicitud se encuentra en proceso de aprobación, te notificaremos una vez que esté lista; mientras tanto puedes continuar llenando tu solicitud ¡Gracias por confiar en RedGirasol!`,
                //     acceptText: "Aceptar",
                //     cancelText: '',
                //     accept: '',
                //     cancel: this.goToHomePage
                // });
                this.$emit("updated", 1);
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif();
                console.log(error);
            }
        },
        validateReferences() {
            const references = this.references;

            if(references[0].name == references[1].name || references[2].name == references[1].name) {
                return false;
            }

            if(references[0].phone == references[1].phone || references[2].phone == references[1].phone) {
                return false;
            }

            if(references[0].email == references[1].email || references[2].email == references[1].email) {
                return false;
            }

            return true;

            // const hasTwoRelatives = references.filter(r => (r.relationType == 1 || r.relationType == 2 || r.relationType == 3)).length == 2;

            // return hasTwoRelatives;

        },
        validateRelatives() {
            const references = this.references;
            const hasTwoRelatives = references.filter(r => (r.relationType == 1 || r.relationType == 2 || r.relationType == 3)).length >= 2;
            return hasTwoRelatives;
        },
        getReferencesTitle(index) {
            let text = "Primera";

            if(index == 1) {
                text = "Segunda";
            } else if(index == 2) {
                text = "Tercera";
            }

            return text;
        },
        onSelect(r, dialCode) {
            this.references[r].dialCode = dialCode.dialCode;
        },
        async getCollections(){
            try {
                let res = await axios.get("/api/v1/forms/getProjectRelatedProfilesRelationTypes");
                this.relationTypes = res.data;

                let options = [];
                this.relationTypes.forEach(opt => {
                    options.push({value: opt.type, text: opt.name}); //conversion a sintaxis de vx-select 
                })
                this.getOptionsForSelect = options;
            } catch (error) {
                this.warn(error);
            }
        },
        async validateForm() {
            return this.$validator.validateAll();
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style scoped>

.document-list {
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.document-card {
    /* border: solid 2px #28de18; */
    border-radius: 15px;
    align-self: center;
    text-align: center;
    /* margin: 1rem; */
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    font-size: larger;
}

ul.dropdown-list {
    z-index: 9999 !important;
}

</style>