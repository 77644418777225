<template>
    <div id="guarantee-documents-control">
        <div v-if="menuOption == 0">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h3 class="mb-1">Documentos requeridos.</h3>
                    <p>Podrás firmar el contrato una vez que validemos la información.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <!-- VERIFICACIÓN DE LA IDENTIDAD -->
            <div class="vx-row mb-4 m-1">
                <div @click.stop="!hasIdentityReminderRequested ? requestReminder(1, getIdentityData().status) : ''" class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>INE</h5>
                            <p class="mb-2">Validación de identidad.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getIdentityData().color">{{ getIdentityData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INFORMACIÓN DEL OS -->
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Comprobante de domicilio</h5>
                            <p class="mb-2">Comprobante de domicilio no mayor a 3 meses, legible, sin cortes.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getAddressData().color">{{ getAddressData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INFORMACIÓN DEL OS -->
            <div class="vx-row mb-4 m-1">
                <div @click.stop="!hasIdentityReminderRequested ? requestReminder(2, getIdentityData().status) : ''" class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Historial crediticio</h5>
                            <p class="">Revisaremos el historial crediticio de tu obligado solidario.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getCDCStatus().color">{{ getCDCStatus().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
                </div>
            </div>
        </div>
        <!-- INFORMACIÓN DEL DOMICILIO -->
        <div v-if="menuOption == 1">
            <GuaranteeAddressDocument
                :onboardingStepData="onboardingStepData"
                :document="document"
                @on-back="menuOption = 0"
                @on-continue="goContinue"
                @updated="getProjectData"/>
        </div>

        <mati-popup
            v-if="isMatiReady"
            :user-id-to-activate="matiData.userId" 
            @on-mati-process-started="onMatiProcessStarted" />
    </div>
</template>

<script>
import MatiPopup from "@components/mati/MatiPopup";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import GuaranteeAddressDocument from "./GuaranteeAddressDocument.vue";

const PF_ADDRESS_PROOF_DOCUMENT = "applicant_pf_os_address";
const PF_ADDRESS_PROOF_ID = 44;

export default {
    name: 'GuaranteeDocumentsControl',
    props: {
        onboardingStepData: Object
    },
    components: {
        MatiPopup,
        GuaranteeAddressDocument
    },
    data: () => ({
        menuOption: 0,
        personalDataIsCompleted: false,
        hasLocationDocument: false,
        matiData: {
            userId: null,
            personalProfileId: null
        },
        identityIsCompleted: false,
        document: null,
        hasIdentityReminderRequested: false,
        hasCDCReminderRequested: false,
    }),
    async mounted() {
        await this.getDocument();
    },
    computed: {
        isMatiReady() {
            return this.onboardingStepData != null;
        },
        applicant() {
            return this.onboardingStepData.client;
        },
        guarantee() {
            return this.onboardingStepData.finance.guarantee.guarantee_profile;
        },
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        address_document_id() {
            return PF_ADDRESS_PROOF_ID;
        },
        address_document_prefix() {
            return PF_ADDRESS_PROOF_DOCUMENT;
        },
        hasAlreadyDocument() {
            return this.document && this.document.id;
        },
        hasIdentity() {
            return this.guarantee.personal.identity_verifications.length > 0;
        },
    },
    methods: {
        async getProjectData() {
            await this.$emit('updated', 1);
        },
        getLocationData() {
            return {
                color: 'warning',
                status: 'Pendiente'
            }
        },
        goBack() {
            this.$emit('on-back');
        },
        goContinue() {
            this.$emit('on-continue')
        },
        async requestMatiPopUp() {
            await this.clearMatiPoUpSettings();
            this.matiData.userId = this.applicant.user_id;
            this.matiData.personalProfileId = this.guarantee.personal_profile_id;
            this.openMatiPopup();
        },
        async getPersonalIdentityInfo(personalId) {
            this.status = await this.getPersonalIdentityStatus(personalId);
            this.identityIsCompleted = this.verificationIsCompleted(personalId);
        },
        async onMatiProcessStarted() {
              this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
              this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
              this.processWasStarted = true;
          },
        async openMatiPopup() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
        },
        validateIdentity(project) {
            let profile = project.client.user.personal;
            const identityData = profile.identity_verifications;
            return identityData.length > 0;
        },
        clearMatiPoUpSettings() {
            this.matiData = {
                userId: null,
                personalProfileId: null
            }
        },
        async getDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${this.address_document_id}/${this.UserId}`);
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async requestReminder(flow, status) {
            try {
                if(status == "Enviar recordatorio") {
                    this.showLoading(true);
                    const response = await axios.post(`/api/guarantee/post/${this.guarantee.id}/send-reminder/${flow}`);
                    if(flow == 1) {
                        this.hasIdentityReminderRequested = true;
                    } else {
                        this.hasCDCReminderRequested = true;
                    }
                    this.showLoading(false);
                    this.showGuaranteeAlert(flow);
                }
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        showGuaranteeAlert(flow) {
            const text = flow == 1 ? "verifique su identidad" : "autorización para consultar su historial crediticio";
            this.$vs.dialog({
                color: 'success',
                title: 'Notificaremos a tu obligado.',
                text: `Enviamos un correo a tu Obligado Solidario en donde se le solicitará la ${text}.
                        \n Por favor comunícate con el/ella para que no se extrañe de este correo y nos ayude en tu proceso de solicitud de crédito.`,
                acceptText: "Continuar",
            });
        },

        // VALIDACIONES
        getIdentityData() {
            let data = {
                status: "Enviar recordatorio",
                color: "warning"
            };

            if(this.hasIdentity) {
                const identity = this.guarantee.personal.identity_verifications[0];
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0) {
                    data.status = "Cargado";
                    data.color = "warning";
                } else if(identity.identity_status == 2) {
                    data.status = "En revisión";
                    data.color = "warning";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "danger";
                } else {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        },
        getAddressData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasAlreadyDocument) {
                if(this.document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getCDCStatus() {
            let data = {
                status: "Enviar recordatorio",
                color: "warning"
            };

            if(this.guarantee.personal.last_credit_request != null) {
                data.status = "Completado";
                data.color = "success";
            }

            return data;
        }
    }
}
</script>