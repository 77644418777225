var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"loan-address"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Detalles de empleo.")]),_c('p',[_vm._v("Favor completa la siguiente información.")]),_c('vs-divider')],1),_c('div',{staticClass:"vx-col w-10/12"},[(_vm.isMounted)?_c('div',{staticClass:"main-form mt-2"},[_c('div',{staticClass:"vx-row"},[_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Ocupación")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"mr-2 w-full",class:{
                                'has-error': _vm.hasError('personal_occupation_id'),
                                'is-success': _vm.isSuccess('personal_occupation_id'),
                            },attrs:{"name":"personal_occupation_id","danger":_vm.hasError('personal_occupation_id'),"danger-text":_vm.errorText('personal_occupation_id'),"success":_vm.isSuccess('personal_occupation_id'),"placeholder":"Selecciona una opción.","clearable":false,"searchable":true,"options":_vm.personalActivities(),"reduce":label => label.id},model:{value:(_vm.personal_occupation_id),callback:function ($$v) {_vm.personal_occupation_id=$$v},expression:"personal_occupation_id"}}),(_vm.hasError('personal_occupation_id'))?_c('v-error',{attrs:{"error":_vm.errorText('personal_occupation_id')}}):_vm._e()],1),_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Sector")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"mr-2 w-full",class:{
                                'has-error': _vm.hasError('personal_business_activity_category_id'),
                                'is-success': _vm.isSuccess('personal_business_activity_category_id'),
                            },attrs:{"name":"personal_business_activity_category_id","danger":_vm.hasError('personal_business_activity_category_id'),"danger-text":_vm.errorText('personal_business_activity_category_id'),"success":_vm.isSuccess('personal_business_activity_category_id'),"placeholder":"Selecciona una opción.","clearable":false,"searchable":true,"options":_vm.personalBusinessActivitiesCategoriesFormat(),"reduce":label => label.id},on:{"change":function($event){return _vm.set_personal_business_activities()}},model:{value:(_vm.personal_business_activity_category_id),callback:function ($$v) {_vm.personal_business_activity_category_id=$$v},expression:"personal_business_activity_category_id"}}),(_vm.hasError('personal_business_activity_category_id'))?_c('v-error',{attrs:{"error":_vm.errorText('personal_business_activity_category_id')}}):_vm._e()],1),_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Actividad principal")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"mr-2 w-full",class:{
                                'has-error': _vm.hasError('personal_business_activity_id'),
                                'is-success': _vm.isSuccess('personal_business_activity_id'),
                            },attrs:{"name":"personal_business_activity_id","danger":_vm.hasError('personal_business_activity_id'),"danger-text":_vm.errorText('personal_business_activity_id'),"success":_vm.isSuccess('personal_business_activity_id'),"placeholder":"Selecciona una opción.","clearable":false,"searchable":true,"options":_vm.businessActivitiesFormat(),"reduce":label => label.id,"disabled":_vm.personal_business_activity_category_id == null},model:{value:(_vm.personal_business_activity_id),callback:function ($$v) {_vm.personal_business_activity_id=$$v},expression:"personal_business_activity_id"}}),(_vm.hasError('personal_business_activity_id'))?_c('v-error',{attrs:{"error":_vm.errorText('personal_business_activity_id')}}):_vm._e()],1)]),_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"flex justify-end"},[_c('vs-button',{staticClass:"mt-2 vs-button-dark",on:{"click":function($event){return _vm.saveSection()}}},[_vm._v("Continuar")])],1)])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }