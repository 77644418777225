export default {
  methods: {
    async checkOrCreateInvestorAccount(investor_id){
      let g = await this.getInvestorAccount(investor_id);

      if(g === false){
        return null;
      }

      let account = null;

      // verificar si hay cuentas ligadas al inversionista
      if (g === undefined || g === null) {
        // no hay cuentas de STP ligadas al inversionista, se solicita la creacion
        let nacc = await this.createInvestorAccount(investor_id);
        if(nacc === false){
          return null;
        }
        else {
          account = nacc;
        }
      }
      else {
        account = g;
      }

      // se verifica si la cuenta esta en estatus created
      if(account.status === "created"){
        return account.stp_clabe;
      }
      else{
        // se retorna null para utilizar openpay
        return null;
      }
    },
    async getInvestorAccount(investor_id) {
      let url = `/api/stp/v1/account/investorId/${investor_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        return false;
      }
    },
    async getInvestorAccountStpClabe(investor_id){
      let account = await this.getInvestorAccount(investor_id);
      if(account === false || account === undefined || account === null){
        return null;
      }
      // se verifica si la cuenta esta en estatus created
      if(account.status === "created"){
        return account.stp_clabe;
      }
      else{
        return null;
      }
    },
    async createInvestorAccount(investor_id) {
      let url = `/api/stp/v1/account/investorId/${investor_id}`;
      try {
        const response = await axios.post(url, {});
        const account = response.data;
        if(account.stp_clabe){
          // si la creacion de la cuenta es correcta, se hace un timeout
          // de 7 segundos para dar tiempo a la activación
          await (async() => {
            await this.sleepPromise(7000);
          })();

          // solicitar la informacion de la cuenta de nuevo
          // despues del timeout
          let g = await this.getInvestorAccount(investor_id);
          if(g !== false && g !== null){
            return g;
          }
        }
        // se retorna false en caso de fallo
        return false;
      } catch (error) {
        return false;
      }
    },
    sleepPromise(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    // #region supplier
    async getSupplierAccount(supplier_id) {
      let url = `/api/stp/v1/account/supplierId/${supplier_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        return false;
      }
    },
    async getSupplierAccountStpClabe(supplier_id){
      let account = await this.getSupplierAccount(supplier_id);
      if(account === false || account === undefined || account === null){
        return null;
      }
      // se verifica si la cuenta esta en estatus created
      if(account.status === "created"){
        return account.stp_clabe;
      }
      else{
        return null;
      }
    },

    // #endregion

    //#region applicant 
    async createStpAccount(applicantId) {
      try {
        await axios.post(`/api/stp/v1/account/applicantId/${applicantId}`, {})
      } catch (error) {
        console.log("Error creating STP account for applicant");
      }
    },
    //#endregion
  }
}
