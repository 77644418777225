<template>
    <div id="business-address-confirmation" class="mt-base">
        <div class="vx-row mb-4">
            <div class="vx-col w-full mb-4">
                <h2>Comprobantes de ingresos</h2>
                <p>Compártenos los estados financieros o estados de cuenta de tu empresa.</p>
                <vs-divider></vs-divider>
            </div>
            <div class="ml-4 vx-col sm:w-full md:w-6/12 black">
                <h4 class="mb-2">¿Qué documentos subir?</h4>
                <p class="mb-4">
                    Proporcionar tus comprobantes de ingresos te ayuda a obtener más fácil la aprobación de tu crédito. 
                    Puedes elegir entre subir tus <b>Estados financieros</b> o <b>Estados de cuenta</b> según tu preferencia
                    y una vez seleccionado, deberás cumplir con todos los documentos solicitados.
                </p>
            </div>
            <div class="ml-4">
                <vs-tabs>
                    <vs-tab :label="getTitle('financialStatements')" class="pt-base">
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-10/12 mb-4">
                                <h4 class="mb-4">Antes de subir los documentos, verifica lo siguiente:</h4>
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-full mb-16">
                                        <div class="document-detail">
                                            <!-- <p class="black mb-8">Antes de subir los documentos, verifica lo siguiente:</p> -->
                                            <ul class="check-ul">
                                                <li class="black">Un documento por cada periodo solicitado (trimestre más reciente, año pasado, año antepasado).</li>
                                                <li class="black">Cada periodo debe incluir <b>Balance general</b>, <b>Estado de resultados</b> y <b>Flujo de efectivo</b>.</li>
                                                <li class="black">Los estados financieros deben estar firmados por tu representante legal y contador.</li>
                                                <li class="black">El archivo pese máximo 50 MB.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-8/12">
                                        <div class="vx-row">
                                            <document-upload
                                                v-for="df in allDocumentFields"
                                                v-if="isMounted && (
                                                    df.formField.db_field == 'loan_pm_financial_statements_1' 
                                                    || df.formField.db_field == 'loan_pm_financial_statements_2' 
                                                    || df.formField.db_field == 'loan_pm_financial_statements_3')"
                                                class="md:w-1/3"
                                                :key="df.id"
                                                :target_id="df.target_id"
                                                :f="df.formField"
                                                :doc="df.documentSet"
                                                :inputClassExtraLarge="false"
                                                :inputClassLarge="true"
                                                :disabled="isDisabled"
                                                :evaluator="evaluateCondition"
                                                :loadedState="df.loadedState"
                                                :stickToLoadedState="true"
                                                :from_project_id="currentProjectId"
                                                :editableByBackoffice="true"
                                                @on-success="onDocumentUploadSuccess"
                                                :title="getDocumentTittle(df.formField.db_field)"
                                            />
                                        </div>
    
                                        <p class="text-sm">
                                            Si no cuentas con la información de los periodos mencionados por el tiempo de operación de tu empresa, complementa el campo con el documento más reciente.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab :label="getTitle('bankStatements')" class="pt-base">
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-10/12 mb-4">
                                <h4 class="mb-4">Antes de subir los documentos, verifica lo siguiente:</h4>
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-full mb-16">
                                        <div class="document-detail">
                                            <p class="black mb-8">Antes de subir los documentos, verifica lo siguiente:</p>
                                            <ul class="check-ul">
                                                <li class="black">Un documento por cada periodo solicitado (últimos tres meses).</li>
                                                <li class="black">Cada periodo debe incluir <b>Balance general</b>, <b>Estado de resultados</b> y <b>Flujo de efectivo</b>.</li>
                                                <li class="black">Los estados de cuenta deben ser emitidos por la institución bancaria donde tienes registrado tu cuenta.</li>
                                                <li class="black">El archivo pese máximo 50 MB.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-8/12">
                                        <div class="vx-row">
                                            <document-upload
                                                v-for="df in allDocumentFields"
                                                v-if="isMounted && (
                                                    df.formField.db_field == 'applicant_pm_bank_statements_1' 
                                                    || df.formField.db_field == 'applicant_pm_bank_statements_2' 
                                                    || df.formField.db_field == 'applicant_pm_bank_statements_3')"
                                                class="md:w-1/3"
                                                :key="df.id"
                                                :target_id="df.target_id"
                                                :f="df.formField"
                                                :doc="df.documentSet"
                                                :inputClassExtraLarge="false"
                                                :inputClassLarge="true"
                                                :disabled="isDisabled"
                                                :evaluator="evaluateCondition"
                                                :loadedState="df.loadedState"
                                                :stickToLoadedState="true"
                                                :from_project_id="currentProjectId"
                                                :editableByBackoffice="true"
                                                @on-success="onDocumentUploadSuccess"
                                                :title="getBankAccountDocumentTittle(df.formField.db_field)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col sm:w-1/2 md:w-1/12">
                <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
            </div>
            <div class="vx-col sm:w-1/2 md:w-2/12">
                <!-- <vs-button class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button> -->
            </div>
        </div>
    </div>
</template>

<script>
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import dateHelper from '@mixins/dateHelper';
import formatHelper from '@mixins/formatHelper';

const verifiedEnumValue = "verificado";

import VxUpload from '@upload/VxUpload';
export default {
    name: "ApplicantBusinessConstitutiveDocument",
    props: ["onboardingStepData", "document", 'financialStatements', 'bankStatements'],
    mixins: [docsHelper, formHelper, dateHelper, formatHelper],
    components: {
        VxUpload
    },
    data: () => ({
        onError: false,
        onSuccess: false,
        hasFile: false,
        /* mensajes */
        genericError: "Ha ocurrido un error con la carga.",
        invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
        updateError: "Ha ocurrido un error al intentar actualizar",
        remainMssg: "El archivo cargado previamente seguirá activo.",
        apiErrors: "",
        // DOCUMENTS
        pmSections: [28],
        pfSections: [32],
        flow_id_pf: 4,
        flow_id_pm: 5,
        validatedDocuments: [],
        allDocumentFields: [],
        documentsStates: [],
        allSections: [],
        isMounted: false,
        isMoral: true

    }),
    async mounted() {
        await this.getSections();
    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        // apiStorageBasePrefix(){
        //     return this.ApiDomain + "/storagev3/documents/";
        // },
        currentAccessToken(){
            return this.AccessToken;
        },
        uploadAction() {
            let url = this.apiStorageBasePrefix;
            if(this.hasAlreadyDocument) {
                return `${url}upload/user/${this.UserId}/update/${this.document.id}`;
            } else {
                return `${url}upload/user/${this.UserId}`;
            }
            // if (this.fileWasLoaded) {
            //     // actualizacion
            //     if (this.isUserDoc)
            // } else {
            // }
        },
        headers(){
            // "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
            //     "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
            return {
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
                "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": "Bearer " + this.currentAccessToken,
            };
        },
        currentAccessToken(){
            return this.AccessToken;
        },
        showAlerts(){
            return (this.onSuccess || this.onError);
        },
        hasAlreadyDocument() {
            return this.document != null && this.document.id != null;
        },
        isVerified(){
            return (this.document.is_verified == "verificado");
        },
        isRejected(){
            return (this.document.is_verified == "rechazado");
        },
        documentName() {
            return this.document?.storage_file;
        },
        address_document_id() {
            return PM_PROOF_ID;
        },
        address_document_prefix() {
            return PM_PROOF_DOCUMENT;
        },
        dataForm() {
            return {
                file_type: this.address_document_prefix
            };
        },
        currentProjectId() {
            return this.base.id;
        },
        isArchived(){
            return this.base.resolution_date !== null;
        },
        isDisabled(){
            return this.isArchived || this.base.status >= 4;
        },
        hasFinancialStatements() {
            if(this.isMounted) {
                return this.financialStatements[0] != ""
                    || this.financialStatements[1] != ""
                    || this.financialStatements[2] != "";
            } else {
                return false;
            }
        },
        hasBankStatements() {
            if(this.isMounted) {
                return this.bankStatements[0] != ""
                    || this.bankStatements[1] != ""
                    || this.bankStatements[2] != "";
            } else {
                return false;
            }
        },
        getStatementSubtitle() {
            let subtitle = "";
            if(this.hasFinancialStatements) {
                subtitle += "Estados financieros";
            }

            if(this.hasFinancialStatements && this.hasBankStatements) {
                subtitle += " y ";
            }

            if(this.hasBankStatements) {
                subtitle += "Estados de cuenta";
            }

            return subtitle;
        }
    },
    methods: {
        async goBack() {
            await this.$emit('on-back', 1);
        },
        async goContinue() {
            await this.$emit('on-continue', 1);
        },
        async upload() {
            let doc = document.getElementById(";");
            const formData = new FormData();
            const data = {
                file_type: this.address_document_prefix
            };
            this.injectAccountMetadataToFormData(obj);
            for (var key in data) {
                formData.append(key, data[key]);
            }

            if(doc.files.length > 0){
                data.append("applicants_layout", doc.files[0]);
                const res = await axios.post(`${this.uploadAction()}`, data);
            }
        },
        successUpload() {
            this.$vs.notify({
                title: '¡Documento subido!',
                text: 'Tu comprobante de domicilio ha sido subido con éxito.',
                color: 'success',
                position: 'top-right'
            });
        },
        onInvalidExtensionEvent(){
            this.onError = true;
            this.invalidExtension = true;
        },
        async getSections() {
            try {
                this.showLoading(true);
                const sections = this.isMoral ? this.pmSections : this.pfSections;
                const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
                let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pf;
                let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
                let response2 = await axios.get(url);
                this.documentsStates = response2.data;
                this.allSections = res.data.sections;
                this.allDocumentFields = [];

                this.allSections.forEach(s => {
                    // s.containers = this.getContainersForSection(s.id);
                    s.documentFields = [];

                    s.fields.forEach(f => {
                        this.formFieldSetter(f, this);
                        if(f.doc_id != null){
                            if(s.id == 27) {
                                if(f.doc_id == 31 || f.doc_id == 26) 
                                return;
                        }

                        let ff = {formField: f, documentSet: null, target_id: null };
                        let ss = this.documentsStates.find(sid => sid.id == s.id);
                        if(ss && ss.documents){
                            let dset = ss.documents.find(d => d.id == f.doc_id);
                            if(dset){
                                ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                                ff.documentSet = dset;
                            }
                        }
                            s.documentFields.push(ff);
                            this.allDocumentFields.push(ff);
                        }
                    })
                });

                let docRes = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
                if(docRes){
                    this.allDocumentFields.forEach(f => {
                        if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
                            this.validatedDocuments.push(f.documentSet.id);
                        }
                    })
                }
                this.isMounted = true;
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
                console.log(error);
            }
            this.showLoading(false);
        },
        evaluateCondition(condition){
            return this.evaluateConditionBase(this, condition);
        },
        /* obtener ids para los casos correspondientes */
        docTargetEvaluator(cls, cls_2){
            if(cls == 'user'){
                if(cls_2 == 'applicant'){
                    return this.base.client.user.id;
                }
            }
            else if(cls == 'project'){
                return this.base.id;
            }
            this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
            return null;
        },
        onDocumentUploadSuccess(data){
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
        },
        getDocumentTittle(prefix) {
            let title = "";
            const currentYear = new Date(this.onboardingStepData.created_at).getFullYear();
            const currentMonth = new Date(this.onboardingStepData.created_at).getMonth();
            let monthSpecial = currentMonth < 3 ? 1 : 0;
            
            switch(prefix) {
                case "loan_pm_financial_statements_1":
                    title = "Cierre trimestre </br> más reciente";
                    break;
                case "loan_pm_financial_statements_2":
                    title = `Cierre año </br> ${currentYear-(1 + monthSpecial)}`;
                    break;
                case "loan_pm_financial_statements_3":
                    title = `Cierre año </br> ${currentYear-(2 + monthSpecial)}`;
                    break;
            }

            return title;
        },
        getBankAccountDocumentTittle(prefix) {
            let title = "";
            const currentYear = new Date(this.onboardingStepData.created_at).getFullYear();
            switch(prefix) {
                case "applicant_pm_bank_statements_1":
                    title = `${ this.capitalizeFirstLetter(this.getPreviousMonth(this.onboardingStepData.created_at, "MMMM YYYY")) }`;
                    break;
                case "applicant_pm_bank_statements_2":
                    title = `${ this.capitalizeFirstLetter(this.getPreviousMonth(this.onboardingStepData.created_at, "MMMM YYYY", 2)) }`;
                    break;
                case "applicant_pm_bank_statements_3":
                    title = `${ this.capitalizeFirstLetter(this.getPreviousMonth(this.onboardingStepData.created_at, "MMMM YYYY", 3)) }`;
                    break;
            }

            return title;
        },
        // onSuccessEvent(res){
        //     let data = JSON.parse(res);
        //     this.resetFlags();
        //     this.apiErrors =  "",
        //     this.onSuccess = true;
        //     // this.fileWasLoaded = true;
        //     // this.visibleStateLoaded = true;
        //     // this.empty = false;

        //     this.document.verified = (data.is_verified == verifiedEnumValue);
        //     this.document.rejected = (data.is_verified == rejectedEnumValue);
        //     this.document.db_id = data.id;
        //     this.document.updated_at = data.updated_at;

        //     this.$emit("on-success", data);
        // },
        countDocumentsPending(documentList){
            const newArray = documentList.filter(doc => doc == "");
            return newArray.length;
        },
        getTitle(type){
            let list = null;
            let text = "";
            if(type == "financialStatements"){
                list = this.financialStatements;
                text = "Estados financieros";
            } else {
                list = this.bankStatements;
                text = "Estados de cuenta";
            }
    
            if(list != null) {
                let count = this.countDocumentsPending(list);
                if(count < 3) {
                    text += ` (${count} pendientes)`;
                }
            }
    
            return text;
        }
    },
}
</script>

<style>

.address-card {
    border-radius: 8px;
    border: 1px solid #28DE18;
    padding: 1rem;
    width: fit-content;
}

.vs-button.small:not(.includeIconOnly) {
    padding: 1px
}

.address-card {
    padding: 2rem;
    width: fit-content;

}

.center {
    text-align: -webkit-center;
    margin-right: 10px;

}

.center.phone p {
    margin-left: -1rem;
}

.flex-end {
    place-items: flex-end;
}

ul.check-ul {
    list-style: none;
    line-height: 35px;
}

    ul.check-ul li:before {
        content: '✓';
        color: #28DE18;
        margin-right: 12px;
    }

.con-input-upload {
    background-color: #f7fff6;
    width: inherit;
    margin: 0;
    margin-left: 0;
    /* width: 35rem !important; */
    font-size: 1.3rem !important;
}

.doc-list {
    list-style: disc;
    margin-left: 1.3rem;
}
</style>